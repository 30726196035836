import React from 'react'
import { Link, Route } from 'react-router-dom';
import PropTypes from 'prop-types'

/**
 * A <Link> wrapper that knows if it's "active" or not.
 */
const NavLinkListItem = ({
  to,
  exact,
  strict,
  location,
  activeClassName,
  className,
  activeStyle,
  style,
  isActive: getIsActive,
  'aria-current': ariaCurrent,
  ...rest
}) => {
  const path = typeof to === 'object' ? to.pathname : to

  // Regex taken from: https://github.com/pillarjs/path-to-regexp/blob/master/index.js#L202
  const escapedPath = path.replace(/([.+*?=^!:${}()[\]|/\\])/g, '\\$1')

  return (
    <Route
      path={escapedPath}
      exact={exact}
      strict={strict}
      location={location}
      children={({ location, match }) => {
        const isActive = !!(getIsActive ? getIsActive(match, location) : match)

        return (
          <li className={isActive ? [ className, activeClassName ].filter(i => i).join(' ') : className}
              style={isActive ? { ...style, ...activeStyle } : style}
              aria-current={isActive && ariaCurrent || null}
              >
            <Link to={to} {...rest} />
          </li>
        )
      }}
    />
  )
}

NavLinkListItem.propTypes = {
  to: Link.propTypes.to,
  exact: PropTypes.bool,
  strict: PropTypes.bool,
  location: PropTypes.object,
  activeClassName: PropTypes.string,
  className: PropTypes.string,
  activeStyle: PropTypes.object,
  style: PropTypes.object,
  isActive: PropTypes.func,
  'aria-current': PropTypes.oneOf(['page', 'step', 'location', 'date', 'time', 'true'])
}

NavLinkListItem.defaultProps = {
  activeClassName: 'active',
  'aria-current': 'true'
}

export default NavLinkListItem
