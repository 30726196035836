import React, { Component } from 'react';
import { Route, NavLink } from 'react-router-dom';

import Toast from '../components/Toast';
import Section from '../components/Section';

import { getMyOrders } from '../services/api';
import { getReadableDateTimeString } from '../services/util';

export default class MyOrders extends Component {

  state = {
    orders: null,
    loading: true
  }

  componentDidMount() {
    console.log("MyOrders: componentDidMount", new Date().toISOString(), this.props);
    this._loadMyOrders();
  }

  async _loadMyOrders() {
    try {
      this.toastId = Toast.info("Loading..", null, false);
      const response = await getMyOrders();
      Toast.dismiss(this.toastId);
      const orders = response.data.orders;
      if (orders instanceof Array) {
        this.setState({ loading: false, orders });
      } else {
        this.setState({ loading: false });
      }
    } catch (e) {
      Toast.error("Unable to load the page. Please reload the page and try.", this.toastId);
      console.log('Error in _loadMyOrders', e);
    }
  }

  render() {
    const { orders, loading } = this.state;
    return (
      <div className="animated fadeIn">
        <Section>
          <div className="heading">
            <h2>My Orders</h2>
          </div>
          {
            loading ? <h4>Loading..</h4>
              : (orders instanceof Array && orders.length > 0)
                ? <OrdersTable orders={orders} />
                : <div>
                  <div className="animated fadeIn alert alert-warning">You have no previous orders.</div>
                  <div>
                    <NavLink to="/products" className="btn btn-default"><i className="fa fa-chevron-left"></i> View Products</NavLink>
                  </div>
                </div>
          }
        </Section>
      </div>
    );
  }
}

function OrdersTable({ orders }) {
  const numItems = orders.length;
  return (
    <div className="col-md-9 clearfix" id="basket">
      <div className="box">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>#</th>
                <th>Order ID</th>
                <th>Order placed on</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                orders.map((order, index) =>
                  <OrdersTableRow
                    key={order.id}
                    rowNum={index + 1}
                    order={order}
                  />
                )
              }
            </tbody>
          </table>

        </div>
      </div>
    </div>
  );
}

function OrdersTableRow({ rowNum, order }) {
  return (
    <tr>
      <td>{rowNum}</td>
      <td><NavLink to={'/order-summary/' + order.id} >{order.id}</NavLink></td>
      <td><span style={{ whiteSpace: 'nowrap' }}>{getReadableDateTimeString(order.createdAt)}</span></td>
      <td>{order.status || '-'}</td>
      <td><NavLink to={'/order-summary/' + order.id} className="btn btn-default">View order</NavLink></td>
    </tr>
  );
}