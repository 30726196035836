import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import * as yup from "yup";
import SimpleDialog from '../../components/SimpleDialog.js';
import FileUploadThumbnail from '../../components/FileUploadThumbnail.js';

import Toast from '../../components/Toast';

import { getProductCategoriesWithSubcategories, getProducts } from '../../services/api.js';
import { upsertProduct, deleteProduct } from '../../services/adminApi';
import * as util from '../../services/util';
import { Formik } from 'formik';

export default class Products extends Component {

  state = {
    selectedCategoryId: null,
    selectedSubcategoryId: null,
    categories: [],
    products: [],
    productToEdit: {},
    showProductFormDialog: false
  }

  componentDidMount() {
    this._loadCategories();
  }

  async _loadCategories() {
    try {
      this.toastId = Toast.info("Loading categories..", null, false);
      const response = await getProductCategoriesWithSubcategories();
      Toast.dismiss(this.toastId);
      const categories = response.data.cats;
      this.setState({ categories });
    } catch (e) {
      Toast.error("Error loading categories.", this.toastId);
      console.log('Error in _loadCategories', e);
    }
  }

  handleCategoryChange(e) {
    let selectedCategoryId = e.target.value;
    if (selectedCategoryId == "-1") {
      selectedCategoryId = null;
      const selectedSubcategoryId = null;
      const products = [];
      this.setState({ selectedCategoryId, selectedSubcategoryId, products });
    } else {
      this.setState({ selectedCategoryId });
    }
  }

  handleSubcategoryChange(e) {
    let selectedSubcategoryId = e.target.value;
    if (selectedSubcategoryId == "-1") {
      selectedSubcategoryId = null;
      const products = [];
      this.setState({ selectedSubcategoryId, products });
    } else {
      this._loadProducts(selectedSubcategoryId);
    }
  }

  async _loadProducts(selectedSubcategoryId) {
    try {
      this.toastId = Toast.info("Loading products..", null, false);
      const response = await getProducts(selectedSubcategoryId);
      Toast.dismiss(this.toastId);
      const products = response.data.prods;
      this.setState({ selectedSubcategoryId, products });
    } catch (e) {
      Toast.error("Error loading products.", this.toastId);
      console.log('Error in _loadProducts', e, "selectedSubcategoryId: " + selectedSubcategoryId);
    }
  }

  handleSaveProductSubmit(values) {
    console.log('handleSaveProductSubmit', values);
    const { selectedSubcategoryId } = this.state;
    let product = {
      name: values.name,
      description: values.description || '',
      subCategoryId: selectedSubcategoryId,
      image: values.image,
      tag: values.tag
    };
    if(values.id) {
      product.id = values.id;
    }
    
    this._upsertProduct(product);
  }

  async _upsertProduct(product) {
    try {
      this.toastId = Toast.info(`Saving product '${product.name}'. Please wait..`, null, false);
      const response = await upsertProduct(product);
      Toast.success(`Product '${product.name}' saved.`, this.toastId);
      product = response.data.prod;

      this.setState(prevState => {
        const products = util.upsertArray(prevState.products, product);
        return { products };
      });
      this.resetForm();
    } catch (e) {
      Toast.error("Something went wrong. Please try again.", this.toastId);
      console.log('Error in _upsertProduct', e, product);
    }
  }

  handleProductRemove(product) {
    if(window.confirm(`Delete product '${product.name}' ?`)) {
      this._deleteProduct(product);
    }
  }

  async _deleteProduct(product) {
    try {
      this.toastId = Toast.info(`Deleting product '${product.name}'..`, null, false);
      const response = await deleteProduct(product.id);
      Toast.success(`Product '${product.name}' deleted.`, this.toastId);
      this.setState(prevState => ({
        products: [...prevState.products.filter(a => a.id != product.id)]
      }));
    } catch (e) {
      Toast.error("Something went wrong. Please try again.", this.toastId);
      console.log('Error in _deleteProduct', e, product);
    }
  }

  resetForm() {
    this.setState({ productToEdit: {}, showProductFormDialog: false });
  }

  render() {

    const { selectedCategoryId, selectedSubcategoryId, products, showProductFormDialog, productToEdit } = this.state;
    const categories = this.state.categories instanceof Array ? this.state.categories.filter(c => c.subcategories instanceof Array) : [];
    
    let subcategories = [];
    if(selectedCategoryId) {
      const selectedCategory = categories.find(c => c.id == selectedCategoryId);
      if(selectedCategory && selectedCategory.subcategories instanceof Array) {
        subcategories = selectedCategory.subcategories;
      }
    }

    return (
      <div className="container animated fadeIn">
        <div className="col-md-9">
          <div className="heading">
            <h2>Products</h2>
          </div>

          <div>
            <label>Category</label>
            <select className="form-control" value={selectedCategoryId||'-1'} onChange={(e) => this.handleCategoryChange(e)}>
              <option value="-1">--select--</option>
              {
                categories instanceof Array && categories.filter(c => c.subcategories instanceof Array).map(category =>
                  <option key={category.id} value={category.id}>{category.name}</option>
                )
              }
            </select>
            <br />
            <label>Sub-category</label>
            {
              !selectedCategoryId ? <select className="form-control" disabled /> :
                <select className="form-control" value={selectedSubcategoryId||'-1'} onChange={(e) => this.handleSubcategoryChange(e)}>
                  <option value="-1">--select--</option>
                  {
                    subcategories.map(subcategory =>
                      <option key={subcategory.id} value={subcategory.id}>{subcategory.name}</option>
                    )
                  }
                </select>
            }
          </div>
          <br />

          {
            selectedSubcategoryId == null
              ? <div style={{fontSize: 18}} key={"alertinfo" + selectedSubcategoryId} className="animated fadeIn alert alert-info">Add a new product or view all products by selecting a category and sub-category</div>
              :
              <div>
                <div className="row">
                  <div className="col-md-12">
                    <button onClick={(e) => this.setState({ productToEdit: {}, showProductFormDialog: true })} className="btn btn-default">
                      <i class="fa fa-plus"></i> Add new product
                    </button>
                  </div>
                </div>

                <SimpleDialog
                  show={showProductFormDialog}
                  onClose={() => this.setState({ productToEdit: {}, showProductFormDialog: false })}
                  title={productToEdit.name ? "Edit product" : "Add new product"}
                  body={
                    <Formik
                      initialValues={{
                        id: productToEdit.id,
                        name: productToEdit.name || "",
                        description: productToEdit.description || "",
                        tag: productToEdit.tag || "",
                        image: productToEdit.imageSrc || null
                      }}
                      onSubmit={(values) => this.handleSaveProductSubmit(values)}
                      validationSchema={yup.object().shape({
                        name: yup.string().required(),
                        description: yup.string(),
                        tag: yup.string(),
                        image: yup.mixed()
                      })}
                      render={({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
                        return (
                          <form onSubmit={handleSubmit}>
                            <div className="form-group">
                              <label htmlFor="name">Name</label>
                              <input id="name" name="name" type="text" className="form-control" autoFocus
                                value={values.name} onChange={handleChange} />
                              {errors.name && touched.name &&
                                <p>{errors.name}</p>
                              }
                            </div>

                            <div className="form-group">
                              <label htmlFor="description">Description</label>
                              <textarea id="description" name="description" type="text" className="form-control"
                                value={values.description} onChange={handleChange} />
                              {errors.description && touched.description &&
                                <p>{errors.description}</p>
                              }
                            </div>

                            <div className="form-group">
                              <label htmlFor="tag">Tag</label>
                              <input id="tag" name="tag" type="text" className="form-control"
                                value={values.tag} onChange={handleChange} />
                              {errors.tag && touched.tag &&
                                <p>{errors.tag}</p>
                              }
                            </div>

                            <div className="form-group">
                              <label htmlFor="image">Image</label>
                              <input id="image" name="image" type="file" onChange={(event) => {
                                setFieldValue("image", event.currentTarget.files[0]);
                              }} className="form-control" />
                              {errors.image && touched.image &&
                                <p>{errors.image}</p>
                              }
                              <FileUploadThumbnail file={values.image} />
                            </div>
                            <button type="submit" className="btn btn-primary">Save</button>
                          </form>
                        );
                      }} />
                  }
                />
                <br />
                {
                  (products == null || products.length === 0)
                    ? <div key={selectedSubcategoryId} className="animated fadeIn alert alert-warning">No products present in this category</div>
                    :
                    <div className="row">
                      <div className="col-md-12">
                        <br />
                        <table class="table">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Image</th>
                              <th>Name</th>
                              <th>Tag</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              products.map((product, index) =>
                                <ProductRow
                                  key={product.id}
                                  product={product}
                                  rowNum={index + 1}
                                  onEdit={(product) => this.setState({ productToEdit: product, showProductFormDialog: true })}
                                  onRemove={(product) => this.handleProductRemove(product)}
                                />
                              )
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                }
              </div>
          }
        </div>
      </div>
    );
  }
}

function ProductRow(props) {
  const { product, rowNum, onEdit, onRemove } = props;
  return (
    <tr key={product.id}>
      <th scope="row">{rowNum}</th>
      <td><img src={product.imageSrc} height="50" /></td>
      <td>{product.name}</td>
      <td>{product.tag || '-'}</td>
      <td>
        <button className="btn btn-default" onClick={(e) => onEdit(product)}>
          <i class="fa fa-pencil"></i> EDIT
        </button>&nbsp;&nbsp;
        <button className="btn btn-default" onClick={(e) => onRemove(product)}>
          <i class="fa fa-close"></i> REMOVE
        </button>&nbsp;&nbsp;
        <Link to={'/productDetails/' + product.id} className="btn btn-default" target="_blank">
          <i class="fa fa-sign-out"></i> Open in main site
        </Link>
      </td>
    </tr>
  )
}
