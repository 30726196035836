import React, { Component } from 'react';
import { Alert } from 'react-bootstrap';

import Section from '../components/Section';
import ParagraphWithHeading from '../components/ParagraphWithHeading';

import { sendMessage } from '../services/messages/messages';

export default class ContactUs extends Component {

  state = {
    name: '',
    phone: '',
    email: '',
    subject: '',
    message: '',
    responseType: ''
  }

  isEmpty(str) {
    return !(str && str.trim().length > 0);
  }

  async handleSubmit(e) {
    e.preventDefault();
    //const { name, phone, email, subject, message } = this.state;
    const fieldNames = {
      name: 'name',
      phone: 'phone number',
      email: 'email address',
      subject: 'subject',
      message: 'message'
    };
    for (let key in fieldNames) {
      if (this.isEmpty(this.state[key])) {
        alert("Please enter the " + fieldNames[key]);
        return;
      }
    }

    const { name, phone, email, subject, message } = this.state;
    let responseType = 'sending';
    this.setState({ responseType });

    try {
      const res = await sendMessage({ name, phone, email, subject, message });
      responseType = "success";
      this.resetForm();
    } catch (e) {
      responseType = "error";
    }
    this.setState({ responseType });
  }

  resetForm() {
    this.setState({
      name: '',
      phone: '',
      email: '',
      subject: '',
      message: ''
    });
  }

  render() {
    const { name, phone, email, subject, message, responseType } = this.state;
    return (
      <div className="animated fadeIn">

        <div className="container" id="contact">

          <section className="bar background-white no-mb">

            <div class="row text-center">

              <div class="col-md-12">
                <div class="heading">
                  <h2>Contact form</h2>
                </div>
              </div>

              {
                responseType == 'error' && <MessageBox text="Something went wrong. Please try again." type="danger" />
              }
              {
                responseType == 'success' && <MessageBox text="Thank You. Your message has been sent to the admin." type="success" />
              }
              {
                responseType == 'sending' && <MessageBox text="Sending.." type="info" />
              }

              <div class="col-md-8 col-md-offset-2">
                <form onSubmit={(e) => this.handleSubmit(e)}>
                  <div class="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label for="name">Name / Company Name</label>
                        <input type="text" value={name} onChange={(e) => { if (responseType != '') this.setState({ responseType: '' }); this.setState({ name: e.target.value }) }} className="form-control" id="name" />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label for="phone">Phone Number</label>
                        <input type="text" value={phone} onChange={(e) => { if (responseType != '') this.setState({ responseType: '' }); this.setState({ phone: e.target.value }) }} className="form-control" id="phone" />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label for="email">Email</label>
                        <input type="email" value={email} onChange={(e) => { if (responseType != '') this.setState({ responseType: '' }); this.setState({ email: e.target.value }) }} className="form-control" id="email" />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label for="subject">Subject</label>
                        <input type="text" value={subject} onChange={(e) => { if (responseType != '') this.setState({ responseType: '' }); this.setState({ subject: e.target.value }) }} className="form-control" id="subject" />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label for="message">Message</label>
                        <textarea rows="8" id="message" value={message} onChange={(e) => { if (responseType != '') this.setState({ responseType: '' }); this.setState({ message: e.target.value }) }} className="form-control"></textarea>
                      </div>
                    </div>

                    <div class="col-sm-12 text-center">
                      <button type="submit" class="btn btn-template-main"><i class="fa fa-envelope-o"></i> Send message</button>
                    </div>
                  </div>
                </form>

              </div>
            </div>
          </section>

        </div>
      </div>
    );
  }
}

function MessageBox({ text, type, ...rest }) {
  return (
    <div class="col-md-12" {...rest}><div key={`messagebox-$type`} className="animated fadeIn"><Alert bsStyle={type}>{text}</Alert></div></div>
  )
}