import React from 'react'

const isInt = (value) => /^\d+$/.test(value);

const QuantityInputBox = ({quantity, onQuantityChange}) => {
  return (
    <span>
      <button onClick={(e) => { e.preventDefault(); if(quantity > 0) onQuantityChange(quantity - 1) }} >
        <i className="fa fa-minus"></i>
      </button>
      <input type="number" min="0" step="1"
        style={{ width: 50, textAlign: "center" }}
        value={quantity}
        onChange={(e) => { e.preventDefault(); if(isInt(e.target.value)) onQuantityChange(e.target.value) }} />
      <button onClick={(e) => { e.preventDefault(); onQuantityChange(quantity + 1) }} >
        <i className="fa fa-plus"></i>
      </button>
    </span>
  );
}

export default QuantityInputBox
