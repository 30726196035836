import React, { Component } from 'react';

import NavLinkListItem from './NavLinkListItem'

export default function Navbar({ isAuthenticated, isAdmin, username }) {
  return (
    <div>
      <div className="navbar navbar-default yamm" role="navigation" id="navbar" style={{ borderBottom: "solid 1px #955251" }}>
        <div className="container">
          <NavbarLogo />
          <NavbarLinks isAuthenticated={isAuthenticated} isAdmin={isAdmin} username={username} />
        </div>
      </div>
    </div>
  );
}

function NavbarLogo() {
  return (
    <div className="navbar-header">
      <a className="navbar-brand home" href="/">
        {/* <img src="img/logo.png" alt="Universal logo" className="hidden-xs hidden-sm" />
        <img src="img/logo-small.png" alt="Universal logo" className="visible-xs visible-sm" /><span className="sr-only">Universal - go to homepage</span> "#955251" */}
        <div><p style={{ fontFamily: "serif", fontSize: "32px" }}>MASS <span style={{ color: "#955251", fontSize: "42px" }}>E</span>XPORTS</p><p style={{ fontSize: "14px", textAlign: "center", color: "#444" }}>Paravur, Kollam, Kerala, India</p></div>
      </a>
      <div className="navbar-buttons">
        <button type="button" className="navbar-toggle btn-template-main" data-toggle="collapse" data-target="#navigation">
          <span className="sr-only">Toggle navigation</span>
          <i className="fa fa-align-justify"></i>
        </button>
      </div>
    </div>
  );
}

function NavbarLinks({ isAuthenticated, isAdmin, username }) {
  return (
    <div className="navbar-collapse collapse" id="navigation">

      <ul className="nav navbar-nav navbar-right">
        <NavLinkListItem exact to="/">Home</NavLinkListItem>
        <NavLinkListItem to="/about-us">About us</NavLinkListItem>
        <NavLinkListItem to="/products">Products</NavLinkListItem>
        <NavLinkListItem to="/contact-us">Contact us</NavLinkListItem>
        <NavLinkListItem style={{ borderLeft: "solid 1px #955251" }} to="/cart"><span>Cart</span></NavLinkListItem>
        {
          isAuthenticated &&
          <li className="dropdown" style={{ borderLeft: "solid 1px #955251" }}>
            <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">{username}<span class="caret"></span></a>
            <ul class="dropdown-menu">
              <NavLinkListItem to="/my-orders"><span>My Orders</span></NavLinkListItem>
              <NavLinkListItem to="/my-account"><span>My Account</span></NavLinkListItem>
              <NavLinkListItem to="/logout"><span>Logout</span></NavLinkListItem>
            </ul>
          </li>
        }
        {
          isAuthenticated && isAdmin && <NavLinkListItem style={{ borderLeft: "solid 1px #955251" }} to="/admin"><span>Admin Panel</span></NavLinkListItem>
        }
        {
          !isAuthenticated && <NavLinkListItem style={{ borderLeft: "solid 1px #955251" }} to="/login-signup"><span>Login / Signup</span></NavLinkListItem>
        }
      </ul>

    </div>
  );
}