import React, { Component } from 'react';
import { Route, NavLink } from 'react-router-dom';
import { Formik } from 'formik';
import * as yup from "yup";

import NewlineToBr from '../components/NewlineToBr';
import Toast from '../components/Toast';
import Section from '../components/Section';

import { getMyAccount, editUser } from '../services/api';

export default class MyAccount extends Component {

  state = {
    user: null,
    editAccount: false
  }

  componentDidMount() {
    console.log("MyAccount: componentDidMount", new Date().toISOString(), this.props);
    this._loadMyAccount();
  }

  async _editAccount(values) {
    try {
      this.toastId = Toast.info(`Saving. Please wait..`, null, false);
      const user = await editUser(this.state.user.id, values);
      Toast.success(`Saved`, this.toastId);

      this.setState({ user, editAccount: false, changePassword: false });
    } catch (e) {
      Toast.error("Something went wrong. Please try again.", this.toastId);
      console.log('Error in _editAccount', e, values);
    }
  }

  async _loadMyAccount() {
    try {
      this.toastId = Toast.info("Loading..", null, false);
      const response = await getMyAccount();
      Toast.dismiss(this.toastId);
      const user = response.data;
      this.setState({ user });
    } catch (e) {
      Toast.error("Unable to load the page. Please reload the page and try.", this.toastId);
      console.log('Error in _loadMyAccount', e);
    }
  }

  render() {
    const { user, editAccount, changePassword } = this.state;
    return (
      <div className="animated fadeIn">
        <Section>
          <div className="heading">
            <h2>My Account</h2>
          </div>
          {
            user ? editAccount ? this.renderEditAccount(user) : changePassword ? this.renderChangePassword() : this.renderAccountDetails(user) : null
          }
        </Section>
      </div>
    );
  }

  renderAccountDetails(user) {
    return (
      <div>

        <div className="col-md-9 clearfix">
          <div className="box">
            <button className="btn btn-template-main" onClick={() => this.setState({ editAccount: true, changePassword: false })}>Edit Account</button>
            &nbsp;&nbsp;
            <button className="btn btn-template-main" onClick={() => this.setState({ changePassword: true, editAccount: false })}>Change Password</button>
            <br />
            <br />
            <div className="table-responsive">
              <table className="table">
                <tbody>
                  <tr><th>Name</th><td>{user.name}</td></tr>
                  <tr><th>Email</th><td>{user.email}</td></tr>
                  <tr><th>Phone</th><td>{user.phone}</td></tr>
                  <tr><th>Address</th><td>{user.address ? <NewlineToBr text={user.address} /> : <span style={{color: '#999'}}>-</span>}</td></tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>
    )
  }

  renderEditAccount(user) {
    return (
      <div className="col-md-9 clearfix">
        <div className="box">
          <Formik
            initialValues={{
              name: user.name || '',
              phone: user.phone || '',
              address: user.address || ''
            }}
            onSubmit={(values) => this._editAccount(values)}
            validationSchema={yup.object().shape({
              name: yup.string().required(),
              phone: yup.string().required(),
              address: yup.string(),
            })}
            render={({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
              return (
                <form onSubmit={handleSubmit}>

                  <div className="form-group">
                    <label>Name</label>
                    <input type="text" className="form-control" id="name"
                      value={values.name} onChange={handleChange} />
                    {errors.name && touched.name &&
                      <p>{errors.name}</p>
                    }
                  </div>

                  <div className="form-group">
                    <label>Phone Number</label>
                    <input type="text" className="form-control" id="phone"
                      value={values.phone} onChange={handleChange} />
                    {errors.phone && touched.phone &&
                      <p>{errors.phone}</p>
                    }
                  </div>

                  <div className="form-group">
                    <label>Address</label>
                    <textarea rows='4' className="form-control" id="address"
                      value={values.address} onChange={handleChange} />
                    {errors.address && touched.address &&
                      <p>{errors.address}</p>
                    }
                  </div>

                  <div className="text-center">
                    <button type="submit" className="btn btn-template-primary">Save</button>
                    &nbsp;&nbsp;
                    <button onClick={() => this.setState({ editAccount: false, changePassword: false })} className="btn btn-template-main">Cancel</button>
                  </div>

                </form>
              );
            }} />
        </div>
      </div>
    )
  }

  renderChangePassword() {
    return (
      <div className="col-md-9 clearfix">
        <div className="box">
          <Formik
            initialValues={{
              password: '',
              confirmPassword: '',
            }}
            onSubmit={(values) => this._editAccount({password: values.password})}
            validationSchema={yup.object().shape({
              password: yup.string().required('Password is required').min(6),
              confirmPassword: yup.string()
                .oneOf([yup.ref('password'), null], "Passwords don't match")
                .required('Re-enter password here')
            })}
            render={({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
              return (
                <form onSubmit={handleSubmit}>

                  <div className="form-group">
                    <label htmlFor="password">New Password</label>
                    <input type="password" className="form-control" id="password"
                      value={values.password} onChange={handleChange} />
                    {errors.password && touched.password &&
                      <p>{errors.password}</p>
                    }
                  </div>

                  <div className="form-group">
                    <label htmlFor="confirmPassword">Re-enter Password</label>
                    <input type="password" className="form-control" id="confirmPassword"
                      value={values.confirmPassword} onChange={handleChange} />
                    {errors.confirmPassword && touched.confirmPassword &&
                      <p>{errors.confirmPassword}</p>
                    }
                  </div>

                  <div className="text-center">
                    <button type="submit" className="btn btn-template-primary">Save</button>
                    &nbsp;&nbsp;
                    <button onClick={() => this.setState({ changePassword: false, editAccount: false })} className="btn btn-template-main">Cancel</button>
                  </div>

                </form>
              );
            }} />
        </div>
      </div>
    )
  }
}