import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Navbar from '../components/Navbar';

import Home from './Home';
import AboutUs from './AboutUs';
import Products from './Products';
import ProductDetails from './ProductDetails';
import Cart from './Cart';
import MyOrders from './MyOrders';
import MyAccount from './MyAccount';
import OrderConfirmation from './OrderConfirmation';
import OrderSummary from './OrderSummary';
import ContactUs from './ContactUs';
import LoginSignup from './LoginSignup';
import Logout from './Logout';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';

import { isAuthenticatedUser, getUser } from '../services/api';

function PrivateRoute({ component: Component, ...rest }) {
  return <Route {...rest} render={props => (
    isAuthenticated() ? (
      <Component {...props} />
    ) : (
        <Redirect to={{
          pathname: '/login-signup',
          state: { from: props.location }
        }} />
      )
  )} />
}

function isAuthenticated() {
  console.log("checking auth");
  return isAuthenticatedUser();
}

export default class NonAdminLayout extends Component {

  render() {
    return (
      <div className="App">
        <Header />
        <main id="non-admin-content">
          <Switch>
            <Route exact path="/" name="Home" component={Home} />
            <Route path="/about-us" name="AboutUs" component={AboutUs} />
            <Route path="/products/:subcategoryId?/:productId?" name="Products" component={Products} />
            <Route path="/productDetails/:productId?" name="ProductDetails" component={ProductDetails} />
            <Route path="/contact-us" name="ContactUs" component={ContactUs} />
            <Route path="/cart" name="Cart" component={Cart} />
            <Route path="/login-signup" name="LoginSignup" component={LoginSignup} />
            <Route path="/logout" name="Logout" component={Logout} />
            <Route path="/forgot-password" name="ForgotPassword" component={ForgotPassword} />
            <Route path="/reset-password/:token" name="ResetPassword" component={ResetPassword} />
            <PrivateRoute path="/my-orders" name="MyOrders" component={MyOrders} />
            <PrivateRoute path="/my-account" name="MyAccount" component={MyAccount} />
            <PrivateRoute path="/order-confirmation" name="OrderConfirmation" component={OrderConfirmation} />
            <PrivateRoute path="/order-summary/:orderId/:message?" name="OrderSummary" component={OrderSummary} />
            <Redirect from="/login" to="/login-signup" />
          </Switch>
        </main>
        <Footer />
      </div>
    );
  }
}

function Header() {
  const user = getUser();
  return (
    <header>
      <Navbar isAuthenticated={user != null} isAdmin={user != null && user.isAdmin} username={user != null && user.name} />
    </header>
  );
}

function Footer() {
  return (
    <footer style={{ color: "#000", borderTop: "solid 1px #e9d5d5", backgroundColor: "#fbf8f8", paddingTop: 40 }}>
      <div>
        <div className="row">
          <div className="col-md-4">
            <div className="box-simple">
              <div className="icon">
                <i className="fa fa-map-marker"></i>
              </div>
              <h3>Address</h3>
              <div>Paravur, Kollam, Kerala, India</div>
            </div>
          </div>


          <div className="col-md-4">

            <div className="box-simple">
              <div className="icon">
                <i className="fa fa-phone"></i>
              </div>
              <h3>Phone</h3>
              <div>Phone: +91 474 2518877, +91 474 2514477</div>
              <div>Fax: +91 474 2518899</div>
            </div>
          </div>

          <div className="col-md-4">

            <div className="box-simple">
              <div className="icon">
                <i className="fa fa-envelope"></i>
              </div>
              <h3>Email</h3>
              <div><a href="mailto:mass@massexports.in">mass@massexports.in</a></div>
            </div>
          </div>
        </div>

      </div>
      <div style={{ backgroundColor: "#825c5c", padding: 10, color: "#fff" }} className="row text-center">
        &copy; 2021 Haleelu. All rights reserved
      </div>
    </footer>
  );
}
