import React, { Component } from 'react';
import PropTypes from 'prop-types'

import { logout } from '../services/api';

export default class Logout extends Component {

  componentDidMount(){
    logout();
    setTimeout(() => {
      this.props.history.push("/login-signup");
    }, 1000);
  }

  render() {
    return (
      <h2 style={{padding: 100}} className="loading-text text-center">
        Logging out...
      </h2>
    );
  }
}

