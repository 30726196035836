import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as yup from "yup";

import Toast from '../components/Toast';

import { getUser, login, signup } from '../services/api';

export default class LoginSignup extends Component {

  constructor(props) {
    super(props);
    const user = getUser();
    if (user) {
      //User already logged in
      this.redirectUser(user)
    }
  }

  redirectUser(user) {
    if (user.isAdmin) {
      this.props.history.push('/admin');
    } else {
      this.props.history.push('/cart');
    }
  }

  handleLoginSubmit(values) {
    this._login(values.email, values.password);
  }

  handleSignupSubmit(values) {
    const userData = {
      name: values.name,
      email: values.email,
      phone: values.phone,
      password: values.password,
      address: values.address
    };
    this._signup(userData);
  }

  async _login(email, password) {
    try {
      Toast.dismiss(this.toastId);
      this.toastId = Toast.info(`Verifying email/password. Please wait..`, this.toastId, false);
      const user = await login(email, password);
      Toast.dismiss(this.toastId);
      this.redirectUser(user);
    } catch (e) {
      console.log('Error in _login', e, email);
      if (e.response && e.response.status === 401) {
        Toast.error("Invalid email/password", this.toastId, false);
      } else {
        Toast.error("Something went wrong. Please try again.", this.toastId, false);
      }
    }
  }

  async _signup(userData) {
    try {
      Toast.dismiss(this.toastId);
      this.toastId = Toast.info(`Registering. Please wait..`, this.toastId, false);
      const user = await signup(userData);
      Toast.dismiss(this.toastId);
      this.props.history.push('/my-account');
    } catch (e) {
      console.log('Error in _signup', e, userData.email);
      Toast.error("Something went wrong. Please try again.", this.toastId, false);
    }
  }

  render() {
    return (
      <div className="container">
        <br />
        <div className="row">
          <div className="col-md-6">
            <div className="box">
              <h2 className="text-uppercase">New account</h2>

              <p className="lead">Not our registered customer yet?</p>

              <hr />

              <Formik
                initialValues={{
                  name: '',
                  email: '',
                  phone: '',
                  password: '',
                  confirmPassword: '',
                  address: ''
                }}
                onSubmit={(values) => this.handleSignupSubmit(values)}
                validationSchema={yup.object().shape({
                  name: yup.string().required(),
                  email: yup.string().email().required(),
                  phone: yup.string().required(),
                  password: yup.string().required().min(6),
                  confirmPassword: yup.string()
                    .oneOf([yup.ref('password'), null], "Passwords don't match")
                    .required('Re-enter password here'),
                  address: yup.string()
                })}
                render={({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
                  return (
                    <form onSubmit={handleSubmit}>

                      <div className="form-group">
                        <label>Name</label>
                        <input type="text" className="form-control" id="name"
                          value={values.name} onChange={handleChange} />
                        {errors.name && touched.name &&
                          <p>{errors.name}</p>
                        }
                      </div>

                      <div className="form-group">
                        <label>Email</label>
                        <input type="text" className="form-control" name="email"
                          value={values.email} onChange={handleChange} />
                        {errors.email && touched.email &&
                          <p>{errors.email}</p>
                        }
                      </div>

                      <div className="form-group">
                        <label>Phone Number</label>
                        <input type="text" className="form-control" id="phone"
                          value={values.phone} onChange={handleChange} />
                        {errors.phone && touched.phone &&
                          <p>{errors.phone}</p>
                        }
                      </div>

                      <div className="form-group">
                        <label>Password</label>
                        <input type="password" className="form-control" name="password"
                          value={values.password} onChange={handleChange} />
                        {errors.password && touched.password &&
                          <p>{errors.password}</p>
                        }
                      </div>

                      <div className="form-group">
                        <label>Re-enter Password</label>
                        <input type="password" className="form-control" id="confirmPassword"
                          value={values.confirmPassword} onChange={handleChange} />
                        {errors.confirmPassword && touched.confirmPassword &&
                          <p>{errors.confirmPassword}</p>
                        }
                      </div>

                      <div className="form-group">
                        <label>Address</label>
                        <textarea rows='4' className="form-control" id="address"
                          value={values.address} onChange={handleChange} />
                        {errors.address && touched.address &&
                          <p>{errors.address}</p>
                        }
                      </div>

                      <div className="text-center">
                        <button type="submit" className="btn btn-template-main"><i className="fa fa-user-md"></i> Register</button>
                      </div>
                    </form>
                  );
                }} />
            </div>
          </div>

          <div className="col-md-6">
            <div className="box">
              <h2 className="text-uppercase">Login</h2>

              <p className="lead">Already our customer?</p>

              <hr />
              <Formik
                initialValues={{
                  email: '',
                  password: ''
                }}
                onSubmit={(values) => this.handleLoginSubmit(values)}
                validationSchema={yup.object().shape({
                  email: yup.string().email().required(),
                  password: yup.string().required()
                })}
                render={({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label>Email</label>
                        <input type="text" className="form-control" id="email"
                          value={values.email} onChange={handleChange} />
                        {errors.email && touched.email &&
                          <p>{errors.email}</p>
                        }
                      </div>
                      <div className="form-group">
                        <label>Password</label>
                        <input type="password" className="form-control" id="password"
                          value={values.password} onChange={handleChange} />
                        {errors.password && touched.password &&
                          <p>{errors.password}</p>
                        }
                      </div>
                      <div className="text-center">
                        <button type="submit" className="btn btn-template-primary"><i className="fa fa-sign-in"></i> Log in</button>
                        <Link to={"/forgot-password"}><button className="btn btn-link">Forgot password?</button></Link>
                      </div>
                    </form>
                  );
                }} />
            </div>
          </div>

        </div>


      </div>
    );
  }
}

