import Cookies from 'js-cookie';
import axios from 'axios';
import { API_BASE_URL } from '../config';

export const api = (useAuthToken = true) => {
  let config = {};
  config.baseURL = API_BASE_URL;

  let headers = {};
  if (useAuthToken) {
    const token = getAuthToken();
    if(token) {
      headers['x-auth'] = token;
    }
  }
  if (Object.keys(headers).length > 0) {
    config.headers = headers;
  }
  return axios.create(config);
}

export const login = async (email, password) => {
  const response = await api().post('/users/login', { email, password });
  return extractAndSaveUserData(response);
}

export const signup = async (userData) => {
  const response = await api().post('/users', userData);
  return extractAndSaveUserData(response);
}

export const forgotPassword = (email) => {
  return api().post('/users/forgotPassword', { email });
}

export const validateResetPasswordToken = (token) => {
  return api().post('/users/validateResetPasswordToken', { token });
}

export const resetPassword = (email, password, token) => {
  return api().post('/users/resetPassword', { email, password, token });
}

export const editUser = async (userId, values) => {
  const response = await api().patch(`/users/${userId}`, values);
  const user = response.data.user;
  let userData = getUser();
  userData.name = user.name;
  userData.isAdmin = user.isAdmin;
  Cookies.set('user', userData, { expires: 30 });
  return user;
}

export const getMyAccount = () => {
  return api().get('/users/me');
}

const extractAndSaveUserData = (response) => {
  const token = response.headers['x-auth'];
  if(token){
    const user = response.data;
    const userData = {
      id: user.id,
      name: user.name,
      email: user.email,
      token: token,
      isAdmin: user.isAdmin
    }
    Cookies.set('user', userData, { expires: 30 });
    return userData;
  }
  throw new Error('Token not received from server');
}

export const logout = () => {
  api().delete('/users/me/token');
  deleteCart();
  Cookies.remove('user');
}

const getAuthToken = () => {
  const user = getUser();
  return user && user.token;
}

export const isAuthenticatedAdmin = () => {
  const user = getUser();
  return user && user.isAdmin;
}

export const isAuthenticatedUser = () => {
  return getUser() != null;
}

export const getUser = () => {
  return Cookies.getJSON('user');
}

export const getProductDetails = (productId) => {
  return api().get(`/products/${productId}`);
}

export const getProducts = (subCategoryId, limit, offset) => {
  return api().get(`/products/productUnderSubCategory/${subCategoryId}`, {
    params: { limit, offset }
  });
}

export const setProductQuantityInCart = (product, quantity) => {
  if (!(product && product.id)) {
    console.log("setProductQuantityInCart: no productId");
    return;
  }
  let cart = getCart();
  if (cart) {
    if (quantity) {
      let timestamp = Date.now();
      if (cart[product.id]) {
        timestamp = cart[product.id].timestamp;
      }
      cart[product.id] = { product, quantity, timestamp };
    } else {
      cart[product.id] = null;
    }
    setCart(cart);
  } else {
    if (quantity) {
      cart = {};
      cart[product.id] = { product, quantity, timestamp: Date.now() };
      setCart(cart);
    }
  }
}

export const getProductQuantityInCart = (productId) => {
  const cart = getCart();
  if (cart == null || cart[productId] == null) {
    return 0;
  }
  return cart[productId].quantity || 0;
}

export const removeProductFromCart = (productId) => {
  const cart = getCart();
  if (cart == null) {
    return;
  }
  cart[productId] = null;
  setCart(cart);
}

export const getCart = () => {
  let cart = Cookies.getJSON("cart-json");
  if (cart instanceof Object) {
    console.log("cart", cart);
    let nonEmptyCart = {};
    for (var i in cart) {
      let p = cart[i];
      if (p != null && p.product != null && p.product.id != null && p.quantity != null && p.quantity != 0) {
        nonEmptyCart[p.product.id] = p;
      }
    }

    console.log("cart after filter", nonEmptyCart);
    if (Object.keys(nonEmptyCart).length > 0) {
      return nonEmptyCart;
    }
  }
  return null;
}

export const placeOrder = (cart, shippingAddress) => {
  const order = createOrderFromCart(cart);
  order.shippingAddress = shippingAddress;
  return api().post('/orders', order);
}

export const sendPaymentDetails = (orderId, details) => {
  return api().post(`/orders/payments/${orderId}`, details);
}

const createOrderFromCart = (cart) => {
  let order = {
    products: []
  };
  for(let i in cart) {
    const item = cart[i];
    order.products.push({
      productId: item.product.id,
      quantity: item.quantity
    });
  }
  return order;
}

export const getOrder = (orderId) => {
  return api().get(`/orders/${orderId}`);
}

export const cancelOrder = (orderId) => {
  return api().patch(`/orders/${orderId}`, {status: 'Cancelled'});
}

export const getMyOrders = () => {
  return api().get('/orders/myorders');
}

const setCart = (cart) => {
  Cookies.set("cart-json", cart, { expires: 7 });
}

export const deleteCart = () => {
  Cookies.remove("cart-json");
}

export const getProductCategoriesWithSubcategories = () => {
  return api().get('/productCategories');
}
