import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';

const OrderTable = ({ rows, ...rest}) => {
  return (
    <div className="table-responsive" {...rest}>
      <table className="table">
        <thead>
          <tr>
            <th>#</th>
            <th colSpan="2">Product</th>
            <th>Quantity</th>
          </tr>
        </thead>
        <tbody>
          {
            rows.map((row, index) => {
              console.log('order row', row);
              return (<OrderTableRow
                key={row.product.id}
                rowNum={index + 1}
                product={row.product}
                quantity={row.quantity}
              />)
            }
            )
          }
        </tbody>
      </table>
    </div>
  )
}

const OrderTableRow = ({ rowNum, product, quantity }) => {
  return (
    <tr>
      <td>{rowNum}</td>
      <td style={{ width: 1 }}>
        <Link to={'/productDetails/' + product.id} >
          <img height={50} src={product.imageSrc} alt={product.name} />
        </Link>
      </td>
      <td>
        <Link to={'/productDetails/' + product.id} >{product.name}</Link>
      </td>
      <td>{quantity}</td>
    </tr>
  );
}

export default OrderTable;

