import { api } from './api';
import { createFormData } from './util';

export const upsertProduct = (product) => {
  const formData = createFormData(product, ['name', 'description', 'subCategoryId', 'tag'], ['image']);
  if(product.id) {
    return api().patch(`/products/${product.id}`, formData);
  }
  return api().post('/products', formData);
}

export const deleteProduct = (productId) => {
  return api().delete(`/products/${productId}`);
}

export const upsertCategory = (category) => {
  if(category.id) {
    return api().patch(`/productCategories/${category.id}`, category);
  }
  return api().post('/productCategories', category);
}

export const upsertSubcategory = (subcategory, category) => {
  if(subcategory.id) {
    return api().patch(`/productCategories/subCategories/${subcategory.id}`, subcategory);
  }
  subcategory.categoryId = category.id;
  return api().post('/productCategories/subCategories', subcategory);
}

export const deleteCategory = (categoryId) => {
  return api().delete(`/productCategories/${categoryId}`);
}

export const deleteSubcategory = (subcategoryId, categoryId) => {
  return api().delete(`/productCategories/subCategories/${subcategoryId}`);
}

export const getAllOrders = () => {
  return api().get('/orders');
}

export const changeOrderStatus = (orderId, status) => {
  return api().patch(`/orders/${orderId}`, {status});
}

export const getAllMessages = () => {
  return api().get('/messages');
}

export const markMessageAsRead = (messageId) => {
  return api().patch(`/messages/${messageId}`, {isRead: true});
}

export const deleteMessage = (messageId) => {
  return api().delete(`/messages/${messageId}`);
}