import React, { Component } from 'react';

import Section from '../components/Section';
import ParagraphWithHeading from '../components/ParagraphWithHeading';

export default class Home extends Component {

  componentDidMount() {
    window.loadPlugins();
  }

  render() {
    return (
      <div className="animated fadeIn">
        <section>

          <div className="home-carousel">
            <div className="dark-mask"></div>
            <div className="container">
              <div className="homepage owl-carousel">
                <div className="item">
                  <div className="row">
                    <div className="col-sm-5 right">
                      <h1>Superior quality Coir Mats</h1>
                    </div>
                    <div className="col-sm-7">
                      <img height="200" src="http://massexports.in/images/CM/FM/FFID.%20001.jpg" alt="" />
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="row">

                    <div className="col-sm-7 text-center">
                      <img height="200" src="http://massexports.in/images/TM/TM/BCRM.%20001.jpg" alt="" />
                    </div>

                    <div className="col-sm-5">
                    <h1>Superior quality Tuffy Mats</h1>
                    </div>

                  </div>
                </div>
                <div className="item">
                  <div className="row">
                    <div className="col-sm-5 right">
                      <h1>Superior Quality PVC Coir Mats</h1>
                    </div>
                    <div className="col-sm-7">
                      <img height="200" src="http://massexports.in/images/PVCM/PVCM.%20001.jpg" alt="" />
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="row">
                    <div className="col-sm-7">
                      <img height="200" src="http://massexports.in/images/PM/PPM.%20001.jpg" alt="" />
                    </div>
                    <div className="col-sm-5">
                      <h1>Superior Quality Poly Mats</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </section>
        <Section>
          <div>
            <div className="heading text-left">
              <h2>Welcome to Mass Exports</h2>
            </div>
            <div className="row">
              <div className="col-md-8">
                <p className="lead text-justify">We, at Mass Exports, have a multitude of coir products catering to the taste and requirements of the customers. We manufacture and export products such as Coir Yarn in Spools and Bales, Coir Door Mats, Coir Matting Rolls-Latex backed, Coir Rugs stitched with Cotton / Tapestry, Coir Geotextiles, Coir Mourzouk Carpet in attractive designs, Jute Rugs with printed designs and Loop style, Grass Mats, Banana Fibre products, Place Mats and Table Mats from Banana in fibre , Rubber based/ Moulded doormats with attractive patterns, Cotton Place Mats, Napkins, and many other decorative items from different types of raw materials.</p>
              </div>
              <div className="col-md-4">
                <div className="homepage owl-carousel">
                  <div><img style={{margin:"auto"}} src="https://storage.googleapis.com/mass-exports-storage/homepage/1.jpg" className="img-responsive" /></div>
                  <div><img style={{margin:"auto"}} src="https://storage.googleapis.com/mass-exports-storage/homepage/2.jpg" className="img-responsive" /></div>
                  <div><img style={{margin:"auto"}} src="https://storage.googleapis.com/mass-exports-storage/homepage/3.jpg" className="img-responsive" /></div>
                  <div><img style={{margin:"auto"}} src="https://storage.googleapis.com/mass-exports-storage/homepage/4.jpg" className="img-responsive" /></div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <p className="lead">We produce yarn from its very raw stage to other finished forms in our own factory. Yarn is produced from clean fibre extracted from completely retted husks. This helps us maintain the quality of our products in the long run. We make use of the expertise of our skilled workers to complete each and every stage of the production. Our factory is equipped with all the facilities for retting husks in water. Here we have the state-of-the art machineries and looms for making clean fibre and a wide range of coir products.  Our workers are also trained for making the products more attractive, ornamental and providing it with house-stuffing facilities.</p>
              </div>
            </div>
          </div>
        </Section>
      </div>
    );
  }
}