import React, { Component } from 'react';
import { Link, Switch, Route, Redirect, NavLink } from 'react-router-dom';

import AdminLayout from './pages/AdminLayout';
import NonAdminLayout from './pages/NonAdminLayout';
import Toast from './components/Toast';

import logo from './logo.svg';
import './App.css';

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/admin" name="Admin" component={AdminLayout} />
          <Route path="/" name="Home" component={NonAdminLayout} />
        </Switch>
        <div id="modal-box-root"></div>
        <Toast />
      </React.Fragment>
    );
  }
}

export default App;
