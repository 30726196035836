import React, { Component } from 'react';
import { Route, NavLink } from 'react-router-dom';
import { Formik } from 'formik';
import * as yup from "yup";

import Toast from '../components/Toast';
import Section from '../components/Section';

import { getCart, placeOrder, deleteCart, getMyAccount } from '../services/api';

export default class OrderConfirmation extends Component {

  state = {
    cart: null,
    user: null,
    loading: true
  }

  componentDidMount() {
    console.log("OrderConfirmation: componentDidMount", new Date().toISOString(), this.props);
    const cart = getCart();
    if (cart) {
      this.setState({ cart });
      this._loadMyAccount();
    } else {
      this.setState({ loading: false });
    }
  }

  async _loadMyAccount() {
    try {
      this.toastId = Toast.info("Loading..", null, false);
      const response = await getMyAccount();
      Toast.dismiss(this.toastId);
      const user = response.data;
      this.setState({ user, loading: false });
    } catch (e) {
      Toast.error("Unable to load the page. Please reload the page and try.", this.toastId);
      console.log('Error in _loadMyAccount', e);
    }
  }

  onConfirmOrder(shippingAddress) {
    const { cart } = this.state;
    this._placeOrder(cart, shippingAddress);
  }

  async _placeOrder(cart, shippingAddress) {
    try {
      this.toastId = Toast.info(`Placing order. Please wait..`, null, false);
      const response = await placeOrder(cart, shippingAddress);
      Toast.dismiss(this.toastId);
      const orderId = response.data.order.id;
      deleteCart();
      this.props.history.push(`/order-summary/${orderId}/success`);
    } catch (e) {
      Toast.error("Something went wrong. Please try again.", this.toastId, false);
      console.log('Error in _placeOrder', e, cart);
    }
  }

  render() {
    const { cart, user, loading } = this.state;
    return (
      <div className="animated fadeIn">
        <Section>
          <div className="heading">
            <h2>Confirm Order</h2>
          </div>
          {
            loading ? <h4>Loading..</h4>
              : cart == null ?
                <div>
                  <div className="animated fadeIn alert alert-warning">Cart is empty</div>
                  <div>
                    <NavLink to="/products" className="btn btn-default"><i className="fa fa-chevron-left"></i> Back to Products</NavLink>
                  </div>
                </div>
                : <CartTable cart={cart} user={user} onConfirmOrder={(shippingAddress) => this.onConfirmOrder(shippingAddress)} />
          }
        </Section>
      </div>
    );
  }
}

function CartTable({ cart, user, onConfirmOrder }) {
  const numItems = Object.keys(cart).length;
  const shippingAddress = user.name + '\n' + (user.address || '') + '\n' + (user.phone ? 'Ph: ' + user.phone : '');
  return (
    <div className="col-md-9 clearfix" id="basket">
      <div className="box">

        <Formik
          initialValues={{
            shippingAddress: shippingAddress
          }}
          onSubmit={(values) => onConfirmOrder(values.shippingAddress)}
          validationSchema={yup.object().shape({
            shippingAddress: yup.string().required('Please enter the shipping address')
          })}
          render={({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th colSpan="2">Product</th>
                        <th>Quantity</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        Object.keys(cart).map(key => cart[key]).sort((a, b) => a.timestamp - b.timestamp).map((p, index) =>
                          <CartTableRow
                            key={p.product.id}
                            rowNum={index + 1}
                            product={p.product}
                            quantity={p.quantity} />)
                      }
                    </tbody>
                  </table>

                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group">
                      <h3><label htmlFor="shippingAddress">Shipping Address</label></h3>
                      <textarea id="shippingAddress" rows="6" name="shippingAddress" type="text" className="form-control"
                        value={values.shippingAddress} onChange={handleChange} />
                      {errors.shippingAddress && touched.shippingAddress &&
                        <p style={{ color: 'red' }}>{errors.shippingAddress}</p>
                      }
                    </div>
                  </div>
                </div>

                <div className="box-footer">
                  <div className="pull-left">
                    <NavLink to="/cart" className="btn btn-default">
                      <i className="fa fa-chevron-left"></i>Edit Cart
                    </NavLink>
                  </div>
                  <div className="pull-right">
                    <button type="submit" className="btn btn-template-primary">
                      Confirm Order<i className="fa fa-chevron-right"></i>
                    </button>
                  </div>
                </div>
              </form>
            );
          }} />

      </div>
    </div>
  );
}

function CartTableRow({ rowNum, product, quantity, onQuantityChange }) {
  return (
    <tr>
      <td>{rowNum}</td>
      <td style={{ width: 1 }}>
        <NavLink to={'/productDetails/' + product.id} >
          <img src={product.imageSrc} alt="" />
        </NavLink>
      </td>
      <td><NavLink to={'/productDetails/' + product.id} >{product.name}</NavLink>
      </td>
      <td>{quantity}</td>
    </tr>
  );
}