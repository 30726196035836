import React, { Component } from 'react';

import Section from '../components/Section';
import ParagraphWithHeading from '../components/ParagraphWithHeading';

export default class AboutUs extends Component {
  render() {
    return (
      <div className="animated fadeIn">
        <Section>
          <ParagraphWithHeading heading="About us">
            <p>Coir, also known by the biological name cocos, is a versatile gift from Mother Nature! It is the natural golden fibre, extracted from the protective husk of coconut, the fruit of Cocos Nucifera. Kerala, the ' God's Own Country ' is famed world wide for manufacturing top-class coir. And, it is the southern part of Kerala that is most popular for coir manufacturing. </p>
            <p>Mass Exports, located at Paravur in Kollam district of Kerala, India, is where superior-quality Paravur Coir Yarn is being produced. Remarkably, in India and abroad Paravur Anjengo Yarn is most widely used for making Power Loom Mattings.</p>
            <p>Mass Exports has a long past. The grand-father of the present proprietor, Mr. Mohan started the firm in 1936 for producing Yarns on a small scale . Later Mr. V. Sreedharan, the father of Mr. Mohan established the firm under the name V. S. COIR MATS & MATTINGS and continued the business on a large scale. In 1974, when Mr. Mohan, being the legal heir of the firm, inherited its proprietorship, he renamed it as Mass Exports. </p>
          </ParagraphWithHeading>
          <ParagraphWithHeading heading="Maintaining Top Quality">
            <p>We produce yarn from its very raw stage to other refined forms in our own factory. Yarn is produced from clean fibre extracted from completely retted husks so as to maintain the required quality of products made of yarn. 
The A to Z stage of production is carried out by skilled and experienced labourers and hence our products are guaranteed in quality, always, in all respects.</p>
          </ParagraphWithHeading>
          <ParagraphWithHeading heading="Our firm">
            <p>Ours is the only firm in southern Kerala which has a factory of its own. It is equipped with all the facilities for retting husks in water. Here we have modern machineries for making clean fibre, different types of looms for producing a variety of coir products, and a team of skilled workers who can produce quality yarn of all kinds. Our workers are also trained for making the products more decorative and providing it with house-stuffing facilities. Notably, Mass Exports is the only firm in Paravur having the license to export coir and allied products.</p>
          </ParagraphWithHeading>
        </Section>
      </div>
    );
  }
}