import React, { Component } from 'react';

export default class ParagraphWithHeading extends Component {
  render() {
    return (
      <div className="col-md-12">
        <div className="heading text-center">
          <h2>{this.props.heading}</h2>
        </div>

        <p className="lead">
          {this.props.children}
        </p>
      </div>
    );
  }
}