import React, { Component } from 'react';
import { Route, NavLink } from 'react-router-dom';
import { Label, Tabs, Tab } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from "yup";

import NewlineToBr from '../components/NewlineToBr';
import Toast from '../components/Toast';
import Section from '../components/Section';

import { getOrder, cancelOrder, sendPaymentDetails } from '../services/api';
import { getReadableDateTimeString } from '../services/util';

export default class OrderSummary extends Component {

  state = {
    message: null,
    order: null,
    loading: true
  }

  componentDidMount() {
    console.log("OrderSummary: componentDidMount", new Date().toISOString(), this.props);

    const { match: { params: { orderId, message } } } = this.props;

    this._loadOrder(orderId, message);
  }

  async _loadOrder(orderId, message) {
    try {
      this.toastId = Toast.info("Loading..", null, false);
      const response = await getOrder(orderId);
      Toast.dismiss(this.toastId);
      const order = response.data.order;
      if (order instanceof Object) {
        this.setState({ loading: false, order, message });
      } else {
        this.setState({ loading: false });
      }
    } catch (e) {
      console.log('Error in _loadOrder', e, orderId, message);
      if (e.response && e.response.status == 404) {
        Toast.dismiss(this.toastId);
        this.setState({ loading: false });
      } else {
        // this.setState({loading: false, message: "Unable to load the page. Please reload the page and try."});
        Toast.error("Unable to load the page. Please reload the page and try again.", this.toastId, false);
      }
    }
  }

  handleCancelOrder() {
    if (window.confirm('Cancel this order ?')) {
      const { order } = this.state;
      this._cancelOrder(order.id);
    }
  }

  async _cancelOrder(orderId) {
    try {
      this.toastId = Toast.info(`Cancelling order. Please wait..`, null, false);
      const response = await cancelOrder(orderId);
      const order = response.data.order;
      Toast.success(`Order cancelled.`, this.toastId);
      this.setState({ order });
    } catch (e) {
      Toast.error("Something went wrong. Please try again.", this.toastId);
      console.log('Error in _cancelOrder', e, orderId);
    }
  }

  handleSubmitPaymentDetails(details, resetForm) {
    const { order } = this.state;
    this._sendPaymentDetails(order.id, details, resetForm);
  }

  async _sendPaymentDetails(orderId, details, resetForm) {
    try {
      this.toastId = Toast.info('Sending payment details. Please wait..', null, false);
      const response = await sendPaymentDetails(orderId, details);
      Toast.success('Payment details sent. Mass Exports team will contact you shortly.', this.toastId);
      const order = response.data.order;
      this.setState({ order });
      resetForm();
    } catch (e) {
      Toast.error("Something went wrong. Please try again.", this.toastId);
      console.log('Error in handleSubmitPaymentDetails', e, details);
    }
  }

  render() {
    const { order, message, loading } = this.state;
    return (
      <div className="animated fadeIn">
        <Section>
          <div className="heading">
            <h2>Order Summary</h2>
          </div>
          {
            loading ? <h4>Loading..</h4>
              : order == null ?
                <div>
                  <div className="animated fadeIn alert alert-warning">Invalid order</div>
                  <div>
                    <NavLink to="/products" className="btn btn-default"><i className="fa fa-chevron-left"></i> Back to Products</NavLink>
                  </div>
                </div>
                : <div>
                  {message == "success" ? <div className="animated fadeIn alert alert-success">Your order has been placed successfully. Mass Exports team will contact you shortly.</div> : null}
                  <h4>Order ID: {order.id}</h4>
                  <p>Order placed on <span style={{ whiteSpace: 'nowrap' }}>{getReadableDateTimeString(order.createdAt)}</span></p>

                  <div className="row well">
                    <div className="col-lg-12">
                      <div style={{ fontSize: 16, fontWeight: "bold" }}>
                        <span>Order Status: </span>
                        {
                          order.status === 'Cancelled'
                            ? <span style={{ color: "#600", fontWeight: "bold" }}>Cancelled</span>
                            : <span style={{ color: "#66e", fontWeight: "bold" }}>{order.status || 'Pending'} &nbsp;&nbsp;<button className="btn btn-default" onClick={() => this.handleCancelOrder()}>Cancel this order</button></span>
                        }
                      </div>
                    </div>
                  </div>
                  <br />

                  <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
                    <Tab eventKey={1} title="Order Details">
                        <div className="heading">
                          <h4>Order Details</h4>
                        </div>
                        <CartTable products={order.products} shippingAddress={order.shippingAddress} />
                    </Tab>
                    <Tab eventKey={2} title="Payment Details">
                      <PaymentDetails payments={order.payments} shippingAddress={order.shippingAddress}
                        onSubmitPaymentDetails={
                          (details, resetForm) => this.handleSubmitPaymentDetails(details, resetForm)
                        } />
                    </Tab>
                  </Tabs>

                  <div className="box" style={{ border: "none" }}>
                    <div className="box-footer">
                      <NavLink to="/my-orders" className="btn btn-default">
                        <i className="fa fa-chevron-left"></i>My Orders
                    </NavLink>
                    </div>
                  </div>
                </div>
          }
        </Section>
      </div>
    );
  }
}

function CartTable({ products, shippingAddress }) {
  const numItems = products.length;
  return (
    <div className="row">
      <div className="col-md-9 clearfix" id="basket">
        <div className="box">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th colSpan="2">Product</th>
                  <th>Quantity</th>
                </tr>
              </thead>
              <tbody>
                {
                  products.map((p, index) =>
                    <CartTableRow
                      key={p.product.id}
                      rowNum={index + 1}
                      product={p.product}
                      quantity={p.quantity} />)
                }
              </tbody>
            </table>

          </div>
          <div className="box-footer">
            <b>Shipping Address:</b>
            <div><NewlineToBr text={shippingAddress} /></div>
          </div>
        </div>
      </div>
    </div>
  );
}

function CartTableRow({ rowNum, product, quantity, onQuantityChange }) {
  return (
    <tr>
      <td>{rowNum}</td>
      <td style={{ width: 1 }}>
        <NavLink to={'/productDetails/' + product.id} >
          <img src={product.imageSrc} alt="" />
        </NavLink>
      </td>
      <td><NavLink to={'/productDetails/' + product.id} >{product.name}</NavLink>
      </td>
      <td>{quantity}</td>
    </tr>
  );
}

function PaymentDetails({ payments, onSubmitPaymentDetails }) {
  return (
    <div className="row">
      <div className="col-md-9 clearfix">
        <div className="heading">
          <h4>Payment Details</h4>
        </div>
        {
          (payments instanceof Array && payments.length > 0) &&
          <div className="box">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Details</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    payments.map(payment =>
                      <tr key={`payment-details-${payment.createdAt}`}>
                        <th><span style={{ color: "#888", whiteSpace: "nowrap" }}>{getReadableDateTimeString(payment.createdAt)}</span></th>
                        <td className="col-sm-2">{payment.amount}</td>
                        <td className="col-sm-7"><NewlineToBr text={payment.details} /></td>
                      </tr>
                    )
                  }
                </tbody>
              </table>
            </div>
          </div>
        }
        <PaymentDetailsForm onSubmit={onSubmitPaymentDetails} />
      </div>
    </div>
  )
}

function PaymentDetailsForm({ onSubmit }) {
  return (
    <Formik
      initialValues={{
        amount: '',
        details: ''
      }}
      onSubmit={(values, { resetForm }) => onSubmit(values, resetForm)}
      validationSchema={yup.object().shape({
        amount: yup.number().required(),
        details: yup.string().required()
      })}
      render={({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
        return (
          <form onSubmit={handleSubmit}>
            <h4>Send payment details</h4>
            <br/>
            <div className="form-group">
              <label htmlFor="amount">Amount</label>
              <input id="amount" name="amount" type="text" className="form-control"
                value={values.amount} onChange={handleChange} />
              {errors.amount && touched.amount &&
                <p>{errors.amount}</p>
              }
              <br/>
              <label htmlFor="details">Payment Details (Eg: online transaction id, bank name etc)</label>
              <textarea id="details" name="details" type="text" className="form-control"
                value={values.details} onChange={handleChange} />
              {errors.details && touched.details &&
                <p>{errors.details}</p>
              }
            </div>
            <button type="submit" className="btn btn-template-primary">Send</button>
          </form>
        );
      }} />
  )
}