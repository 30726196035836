import React, { Component } from 'react';
import { Route, NavLink } from 'react-router-dom';
import { Label } from 'react-bootstrap';

import Toast from '../components/Toast';

import Section from '../components/Section';
import ParagraphWithHeading from '../components/ParagraphWithHeading';
//import CategorySubcategoryList from '../components/CategorySubcategoryList';

import { getProducts, getProductCategoriesWithSubcategories } from '../services/api';

function ProductList(props) {
  const { match: { params } } = props;
  return ("categoryId: " + params.categoryId + ', ' + "subcategoryId: " + params.subcategoryId);
}

export default class Products extends Component {

  state = {
    categorySubcategoryList: [],
    products: [],
    selectedSubcategoryId: null
  };

  componentDidMount() {
    console.log("Products: componentDidMount", new Date().toISOString(), this.props);
    this.loadPage(this.props);
  }

  componentWillReceiveProps(nextProps) {
    console.log("Products: componentWillReceiveProps", new Date().toISOString(), nextProps);
    this.loadPage(nextProps);
  }

  loadPage(props) {
    const { match: { params: { subcategoryId } } } = props;

    let selectedSubcategoryId = null;
    if (this.state.categorySubcategoryList.length === 0) {
      this._loadCategories(subcategoryId);
    } else {
      selectedSubcategoryId = subcategoryId || this.state.selectedSubcategoryId || this.getFirstSubcategoryId(this.state.categorySubcategoryList);
      this._loadProductList(selectedSubcategoryId);
    }
  }

  async _loadCategories(selectedSubcategoryId) {
    try {
      this.toastId = Toast.info("Loading..", null, false);
      const response = await getProductCategoriesWithSubcategories();
      Toast.dismiss(this.toastId);
      const categorySubcategoryList = response.data.cats;
      this.setState({ categorySubcategoryList });

      if(selectedSubcategoryId) {
        this._loadProductList(selectedSubcategoryId);
      } else {
        selectedSubcategoryId = this.getFirstSubcategoryId(categorySubcategoryList);
        this.props.history.push(`/products/${selectedSubcategoryId}`);
      }
    } catch (e) {
      Toast.error("Unable to load the page. Please reload the page and try.", this.toastId);
      console.log('Error in _loadCategories', e);
    }
  }

  async _loadProductList(subcategoryId) {
    if (subcategoryId == this.state.selectedSubcategoryId) {
      return;
    }
    console.log("subcategoryId: ", subcategoryId);
    try {
      this.toastId = Toast.info("Loading..", null, false);
      const response = await getProducts(subcategoryId);
      Toast.dismiss(this.toastId);
      const products = response.data.prods;
      this.setState({ products, selectedSubcategoryId: subcategoryId });
    } catch (e) {
      Toast.error("Unable to load the page. Please reload the page and try.", this.toastId);
      console.log('Error in _loadProductList', e);
    }
  }

  getFirstSubcategoryId(categorySubcategoryList) {
    let firstCategoryId = null;
    let firstSubcategoryId = null;
    categorySubcategoryList.map(category => {
      if (!firstCategoryId) {
        firstCategoryId = category.id;
      }
      if(category.subcategories instanceof Array) {
        category.subcategories.map(subcategory => {
          if (!firstSubcategoryId) {
            firstSubcategoryId = subcategory.id;
          }
        });
      }
    });
    return firstSubcategoryId;
  }

  // onSubcategorySelected(subcategoryId) {
  //   if (!subcategoryId) {
  //     return;
  //   }
  //   this.loadProductList(subcategoryId);
  // }

  render() {
    const { products, selectedSubcategoryId } = this.state;
    const categorySubcategoryList = this.state.categorySubcategoryList instanceof Array ? this.state.categorySubcategoryList.filter(c => c.subcategories instanceof Array) : [];
    return (
      <div className="animated fadeIn">
        <Section>
          <div className="row">
            <CategorySubcategoryList categorySubcategoryList={categorySubcategoryList} selectedSubcategoryId={selectedSubcategoryId} />
            <div className="col-sm-9">

              {
                selectedSubcategoryId == null ? <h4>Loading..</h4>
                  : products.length === 0 ? <div key={selectedSubcategoryId} className="animated fadeIn alert alert-warning">No products present in this category</div>
                    : <div className="row">
                      {
                        products.map(product => <ProductListItem key={product.id} product={product} />)
                      }
                    </div>
              }
            </div>
          </div>
        </Section>
      </div>
    );
  }
}

function CategorySubcategoryList(props) {
  const { categorySubcategoryList, selectedSubcategoryId } = props;
  return (
    <div className="col-sm-3">
      <div className="panel panel-default sidebar-menu">

        <div className="panel-heading">
          <h3 className="panel-title">Categories</h3>
        </div>

        <div className="panel-body">
          <ul className="nav nav-pills nav-stacked category-menu">
            {
              categorySubcategoryList.map(category =>
                <li key={category.id}>
                  <a style={{cursor: 'pointer'}} data-toggle="collapse" data-target={`#collapsible-${category.id}`}>{category.name}</a>
                  <ul id={`collapsible-${category.id}`}
                    className={
                      category.subcategories.findIndex(subcategory => subcategory.id == selectedSubcategoryId) !== -1 ? 'collapse in': 'collapse'
                    }>
                    {
                      category.subcategories.map(subcategory =>
                        <li key={subcategory.id}><NavLink to={'/products/' + subcategory.id} activeStyle={{ backgroundColor: "#ccc", color: "#000" }}>{subcategory.name}</NavLink>
                        </li>
                      )
                    }
                  </ul>
                </li>
              )
            }
          </ul>
        </div>
      </div>
    </div>
  );
}

function ProductListItem(props) {
  const { product } = props;
  return (
    <div className="col-md-4 col-sm-6 animated fadeIn">
      <div className="product">
        <div className="image">
          <NavLink to={'/productDetails/' + product.id} >
            <img src={product.imageSrc} style={{ height: 150 }} alt="" className="img-responsive image1" />
          </NavLink>
        </div>
        <div className="text">
          <h3><NavLink to={'/productDetails/' + product.id} >{product.name}{ product.tag && <Label style={{marginLeft: 8}}>{product.tag}</Label> }</NavLink></h3>
        </div>
      </div>
    </div>
  );
}