import React from 'react'
import PropTypes from 'prop-types'
import { ToastContainer as _ToastContainer, toast as _toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'

const Toast = ({ position, hideProgressBar, ...rest }) => {
  return (
    <_ToastContainer
      position={position === undefined ? "top-center" : position}
      hideProgressBar={hideProgressBar === undefined ? true : hideProgressBar}
      {...rest} />
  )
}

Toast.toast = _toast;

Toast.dismiss = (toastId) => toastId ? _toast.dismiss(toastId) : _toast.dismiss();

Toast.info = (text, toastId, autoClose=true) =>
  toastId
    ? _toast.update(toastId, { render: text, type: _toast.TYPE.INFO, autoClose: autoClose === true ? 5000 : autoClose, className: 'animated flipInY'  })
    : _toast.info(text, { autoClose: autoClose === true ? 5000 : autoClose });

Toast.success = (text, toastId, autoClose=true) =>
  toastId
    ? _toast.update(toastId, { render: text, type: _toast.TYPE.SUCCESS, autoClose: autoClose === true ? 5000 : autoClose, className: 'animated flipInY' })
    : _toast.success(text, { autoClose: autoClose === true ? 5000 : autoClose });

Toast.warn = (text, toastId, autoClose=true) =>
  toastId
    ? _toast.update(toastId, { render: text, type: _toast.TYPE.WARNING, autoClose: autoClose === true ? 5000 : autoClose, className: 'animated flipInY'  })
    : _toast.warn(text, { autoClose: autoClose === true ? 5000 : autoClose });

Toast.error = (text, toastId, autoClose=true) =>
  toastId
    ? _toast.update(toastId, { render: text, type: _toast.TYPE.ERROR, autoClose: autoClose === true ? 5000 : autoClose, className: 'animated shake'  })
    : _toast.error(text, { autoClose: autoClose === true ? 5000 : autoClose });

Toast.default = (text, toastId, autoClose=true) =>
  toastId
    ? _toast.update(toastId, { render: text, type: _toast.TYPE.DEFAULT, autoClose: autoClose === true ? 5000 : autoClose, className: 'animated flipInY'  })
    : _toast(text, { autoClose: autoClose === true ? 5000 : autoClose });

Toast.propTypes = _ToastContainer.PropTypes;

export default Toast;

