import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Alert } from 'react-bootstrap';

import { forgotPassword } from '../services/api';

class ForgotPassword extends Component {

  constructor(props) {
    super(props);

    this.state = {
      email: "",
      displayMessage: "",
      displayMessageType: "",
      isLoading: false
    };
  }

  handleSubmit(event) {
    event.preventDefault();
    const email = this.state.email;
    if (!email) {
      alert("Email cannot be empty");
      return;
    }
    this._forgotPassword(email);
  }

  async _forgotPassword(email) {
    try {
      this.setState({ isLoading: true });
      this.showMessage("Submitting.. Please wait..", "info");

      await forgotPassword(email);

      this.setState({ isLoading: false, email: '' });
      this.showMessage("We have emailed you the link to reset your password.", "success");
    } catch (e) {
      this.setState({ isLoading: false });
      console.log('Error in _forgotPassword', e, email);
      this.showMessage('Something went wrong. Please try again.', 'danger');
    }
  }

  showMessage(message, type) {
    this.setState({displayMessage: message, displayMessageType: type});
  }

  render() {
    return (
      <div style={{padding: "32px"}} className="animated fadeIn">
        <div className='row'>
          <div className='container'>
            <div className='col-md-7'>
            <div className="heading">
              <h2>Forgot Password</h2>
            </div>
              
              <div>
                <p>
                  Enter your email address below and we will email you a link to reset your password.
                </p>
                <div>
                  {
                    this.state.displayMessage &&
                    <Alert key={this.state.displayMessage} className="animated fadeIn" bsStyle={this.state.displayMessageType}>
                      {this.state.displayMessage}
                    </Alert>
                  }
                  <form onSubmit={(e) => this.handleSubmit(e)}>
                    <div className='form-group'>
                      <input type="email" className='form-control' id="email" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} required autoFocus />
                    </div>
                    <button type="submit" className="btn btn-template-primary">SUBMIT</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


export default ForgotPassword;
