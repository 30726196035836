import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'

const SimpleDialog = ({
  show,
  onClose,
  title,
  body,
  footer,
  ...rest
}) => {
  const modalRootElement = document.getElementById('modal-box-root');
  return (
    <Modal show={show} onHide={onClose} container={modalRootElement} {...rest}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {body}
      </Modal.Body>
      {
        footer &&
        <Modal.Footer>
          {footer}
        </Modal.Footer>
      }
    </Modal>
  )
}

SimpleDialog.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  body: PropTypes.element,
  footer: PropTypes.element
}

export default SimpleDialog

