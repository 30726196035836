import React, { Component } from 'react';
import { Route, NavLink } from 'react-router-dom';

import Section from '../components/Section';
import QuantityInputBox from '../components/QuantityInputBox';

import { getCart, setProductQuantityInCart } from '../services/api';

export default class Cart extends Component {

  state = {
    cart: null,
    loading: true
  }

  componentDidMount() {
    console.log("Cart: componentDidMount", new Date().toISOString(), this.props);
    const cart = getCart();
    if (cart) {
      this.setState({ loading: false, cart });
    } else {
      this.setState({ loading: false });
    }
  }

  onQuantityChange(product, newQuantity) {
    if (product && product.id && this.isInt(newQuantity)) {
      console.log("onQuantityChange", product.id + ": " + newQuantity);
      const quantity = parseInt(newQuantity);
      setProductQuantityInCart(product, quantity);
      const cart = getCart();
      this.setState({ cart })
    }
  }

  isInt(value) {
    return /^\d+$/.test(value);
  }

  // onQuantityChange(newQuantity) {
  //   console.log("onQuantityChange", newQuantity);

  //   if(this.isInt(newQuantity)) {
  //     const quantity = parseInt(newQuantity);
  //     this.setState({ quantity });
  //     setProductQuantityInCart(this.state.product.id, quantity);
  //   }
  // }

  render() {
    const { cart, loading } = this.state;
    return (
      <div className="animated fadeIn">
        <Section>
          <div className="heading">
            <h2>Cart</h2>
          </div>
          {
            loading ? <h4>Loading..</h4>
              : cart == null ?
                <div>
                  <div className="animated fadeIn alert alert-warning">Cart is empty</div>
                  <div>
                    <NavLink to="/products" className="btn btn-default"><i className="fa fa-chevron-left"></i> Back to Products</NavLink>
                  </div>
                </div>
                : <CartTable
                  cart={cart}
                  onQuantityChange={(product, q) => this.onQuantityChange(product, q)} />
          }
        </Section>
      </div>
    );
  }
}

function CartTable({ cart, onQuantityChange }) {
  //Object.keys(cart).map((key) => <div key={cart[key].product.id}>{`${cart[key].product.id} -> ${cart[key].product.name} Q: ${cart[key].quantity}`}</div>
  //Object.keys(cart).map((key) => cart[key])
  const numItems = Object.keys(cart).length;
  return (
    <div className="col-md-9 clearfix" id="basket">
      <p className="text-muted lead">You currently have {numItems} item{numItems === 1 ? null : 's'} in your cart.</p>
      <div className="box">

        <form method="post">

          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th colSpan="2">Product</th>
                  <th>Quantity</th>
                </tr>
              </thead>
              <tbody>
                {
                  Object.keys(cart).map(key => cart[key]).sort((a, b) => a.timestamp - b.timestamp).map((p, index) =>
                    <CartTableRow
                      key={p.product.id}
                      rowNum={index+1}
                      product={p.product}
                      quantity={p.quantity}
                      onQuantityChange={(q) => onQuantityChange(p.product, q)} />)
                }
              </tbody>
            </table>

          </div>

          <div className="box-footer">
            <div className="pull-left">
              <NavLink to="/products" className="btn btn-default">
                <i className="fa fa-chevron-left"></i> Back to Products
              </NavLink>
            </div>
            <div className="pull-right">
              <NavLink to="/order-confirmation" className="btn btn-template-primary">
                Place order<i className="fa fa-chevron-right"></i>
              </NavLink>
            </div>
          </div>

        </form>

      </div>
    </div>
  );
}

function CartTableRow({ rowNum, product, quantity, onQuantityChange }) {
  return (
    <tr>
      <td>{rowNum}</td>
      <td style={{ width: 1 }}>
        <NavLink to={'/productDetails/' + product.id} >
          <img src={product.imageSrc} alt="" />
        </NavLink>
      </td>
      <td><NavLink to={'/productDetails/' + product.id} >{product.name}</NavLink>
      </td>
      <td>
        <QuantityInputBox quantity={quantity} onQuantityChange={onQuantityChange} />
      </td>
      <td>
        <div className="pull-right">
          <button
            onClick={(e) => { e.preventDefault(); onQuantityChange(0) }}
            className="btn btn-default">
            <i className="fa fa-trash-o"></i> Remove
          </button>
        </div>
      </td>
    </tr>
  );
}