import moment from 'moment';

export const getReadableDateTimeString = (timestamp, useShortFormat=false) => {
  if(useShortFormat) {
    const t = moment(timestamp);
    if(t.isSame(new Date(),'day')) {
      return t.format('h:mm a');
    }
    return t.format('MMM D');
  }
  return moment(timestamp).format('D-MMM-YYYY, h:mm a');
}

export const isEmptyString = (str) => !(str && str.trim());

export const createFormData = (object = {}, textFields = [], fileFields = []) => {
  let formData = new FormData();
  textFields.forEach(textField => {
    if (object.hasOwnProperty(textField)) {
      formData.append(textField, object[textField])
    }
  });
  fileFields.forEach(fileField => {
    if (object.hasOwnProperty(fileField)) {
      formData.append(fileField, object[fileField])
    }
  });
  return formData;
}

export const upsertArray = (items=[], item, predicate) => {
  let itemsCopy = [...items];
  let index = null;
  if(predicate) {
    index = itemsCopy.findIndex(predicate);
  } else {
    index = itemsCopy.findIndex(a => a.id == item.id);
  }
  if (index === -1) {
    itemsCopy.push(item);
  } else {
    itemsCopy[index] = item;
  }
  return itemsCopy;
}
