import React, { Component } from 'react';
import truncate from 'lodash/truncate';

import Toast from '../../components/Toast';
import NewlineToBr from '../../components/NewlineToBr';
import { getAllMessages, markMessageAsRead, deleteMessage } from '../../services/adminApi.js';
import { getReadableDateTimeString, upsertArray } from '../../services/util';

export default class Messages extends Component {

  state = {
    loading: true,
    messages: [],
    openMessage: null,
    messageFilter: 'Show All'
  }

  componentDidMount() {
    this._loadAllMessages();
  }

  async _loadAllMessages() {
    try {
      this.toastId = Toast.info("Loading..", null, false);
      const response = await getAllMessages();
      Toast.dismiss(this.toastId);
      const messages = response.data.messages;
      if (messages instanceof Array) {
        this.setState({ loading: false, messages });
      } else {
        this.setState({ loading: false });
      }
    } catch (e) {
      Toast.error("Unable to load the page. Please reload the page and try.", this.toastId);
      console.log('Error in _loadAllMessages', e);
    }
  }

  handleMessageRowClicked(message) {
    this.setState({ openMessage: message });
    this._markMessageAsRead(message);
  }

  async _markMessageAsRead(message) {
    try {
      const response = await markMessageAsRead(message.id);
      message = response.data.message;
      this.setState(prevState => {
        const messages = upsertArray(prevState.messages, message);
        return { messages };
      });
    } catch (e) {
      console.log('Error in _markMessageAsRead', e, message);
    }
  }

  handleMessageRowDeleteClicked(message) {
    if (window.confirm(`Delete message ?`)) {
      this._deleteMessage(message);
    }
  }

  async _deleteMessage(message) {
    try {
      this.toastId = Toast.info(`Deleting message..`, null, false);
      const response = await deleteMessage(message.id);
      Toast.success(`Message '${message.name}' deleted.`, this.toastId);
      this.setState(prevState => ({
        messages: [...prevState.messages.filter(a => a.id != message.id)]
      }));
    } catch (e) {
      Toast.error("Something went wrong. Please try again.", this.toastId);
      console.log('Error in _deleteMessage', e, message);
    }
  }

  render() {

    const { loading, openMessage, messageFilter } = this.state;

    let messages = this.state.messages;
    if (messageFilter !== 'Show All') {
      messages = messages.filter(message =>
        messageFilter === 'Unread' ? !message.isRead : message.isRead
      );
    }

    return (
      <div className="container animated fadeIn">
        <div className="col-md-9">
          <div className="heading">
            <h2>Messages</h2>
          </div>

          {!openMessage &&
            <div><select style={{ padding: '8px 16px' }} value={messageFilter || 'Show All'} onChange={(e) => this.setState({ messageFilter: e.target.value })}>
              <option value='Show All'>Show all</option>
              <option value='Unread'>Unread</option>
              <option value='Read'>Read</option>
            </select>
              <br />
              <br />
            </div>
          }

          {
            loading
              ? <h4 key={"alertinfo-loading"}>Loading..</h4>
              : messages.length === 0
                ? <div key={"alertinfo-no-messages"} className="animated fadeIn alert alert-info">No messages</div>
                :
                <div className="row">
                  <div className="col-md-12">

                    {
                      openMessage
                        ? <MessageDetails message={openMessage} onClose={() => this.setState({ openMessage: null })} />
                        : <MessageList messages={messages}
                          onMessageRowClicked={(message) => this.handleMessageRowClicked(message)}
                          onMessageRowDeleteClicked={(message) => this.handleMessageRowDeleteClicked(message)} />
                    }

                  </div>
                </div>
          }
        </div>
      </div>
    );
  }
}

function MessageDetails({ message, onClose }) {
  return (
    <div className="animated fadeIn">
      <div>
        <button onClick={onClose} className="btn btn-default"><i className="fa fa-chevron-left"></i> Back</button>
      </div>
      <hr />

      <div className='row' style={{ fontSize: 18 }}>
        <div className='col-sm-12'>
          <div className='row'  style={{ padding: 8 }}>
            <div className='col-sm-6'>
              From:&nbsp;<b>{message.name}</b>
            </div>
            <div className='col-sm-6'>
              <span className='pull-right' style={{ whiteSpace: 'nowrap' }}>{getReadableDateTimeString(message.createdAt)}</span>
            </div>
          </div>

          <div className='row'  style={{ padding: 8 }}>
            <div className='col-sm-4' style={{ whiteSpace: 'nowrap' }}>
              <span style={{ backgroundColor: "#eee", padding: '4px 8px' }}><i className="fa fa-envelope"></i> {message.email}</span>
            </div>
            <div className='col-sm-8' style={{ whiteSpace: 'nowrap' }}>
              <span style={{ backgroundColor: "#eee", padding: '4px 8px' }}><i className="fa fa-phone"></i> {message.phone}</span>
            </div>
          </div>

          <div className='row'  style={{ padding: 8 }}>
            <div className='col-sm-12'>
              Subject: {message.subject}
            </div>
          </div>
        </div>
      </div>

      <div style={{ border: 'solid 1px #ddd', padding: 12 }}>
        <NewlineToBr text={message.message || "-"} />
      </div>
    </div>
  )
}

function MessageList({ messages, onMessageRowClicked, onMessageRowDeleteClicked }) {
  return (
    <div className="table-responsive">
      <table style={{ color: "#000" }} className="table animated fadeIn">
        <tbody>
          {
            messages.map((message, index) =>
              <MessageRow key={message.id} message={message} rowNum={index + 1}
                onClick={() => onMessageRowClicked(message)}
                onDeleteClick={() => onMessageRowDeleteClicked(message)} />
            )
          }
        </tbody>
      </table>
    </div>
  )
}

function MessageRow({ message, rowNum, onClick, onDeleteClick }) {
  const rowFontWeight = message.isRead ? 'normal' : 'bold';
  const rowBackgroundColor = message.isRead ? '#f6f6f6' : '#fff';
  return (
    <React.Fragment>
      <tr style={{ cursor: 'pointer', fontWeight: rowFontWeight, backgroundColor: rowBackgroundColor }}>
        <td style={{ whiteSpace: 'nowrap' }} onClick={onClick}>
          {message.name}
        </td>
        <td onClick={onClick} style={{ maxWidth: 600, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
          <span>{message.subject}</span>
          <span style={{ color: "#777" }}> &ndash; {truncate(message.message, { length: 150 })}</span>
        </td>
        <td onClick={onClick} title={getReadableDateTimeString(message.createdAt)} style={{ whiteSpace: 'nowrap' }}>
          {getReadableDateTimeString(message.createdAt, true)}
        </td>
        <td style={{ padding: 0, verticalAlign: 'middle' }}>
          <button className='btn btn-link btn-link-light-grey' onClick={onDeleteClick}><i className="fa fa-close"></i></button>
        </td>
      </tr>
    </React.Fragment>
  )
}
