import React from 'react'
import PropTypes from 'prop-types'

const NewlineToBr = ({ text }) => {
  if (text && typeof text === 'string') {
    return text.split('\n').map(line => <React.Fragment>{line}<br /></React.Fragment>)
  }
  return null;
}

NewlineToBr.propTypes = {
  text: PropTypes.string
}

export default NewlineToBr

