import React, { Component } from 'react';
import { Switch, Route, Redirect, NavLink, Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from "yup";

import NewlineToBr from '../../components/NewlineToBr';
import Toast from '../../components/Toast';
import SimpleDialog from '../../components/SimpleDialog';
import OrderTable from '../../components/OrderTable';

import { getAllOrders, changeOrderStatus } from '../../services/adminApi.js';
import { getOrder } from '../../services/api.js';
import { getReadableDateTimeString, upsertArray } from '../../services/util';

export default class Orders extends Component {

  state = {
    loading: true,
    orders: [],
    showOrder: null,
    statusFilter: 'Show All',
    statusTypes: {
      'Pending': 'Open',
      'Confirmed': 'Open',
      'Payment Details Submitted': 'Open',
      'Payment Confirmed': 'Open',
      'Dispatched': 'Open',
      'Completed': 'Closed',
      'Cancelled': 'Closed',
      'Rejected': 'Closed'
    },

  }

  componentDidMount() {
    this._loadAllOrders();
  }

  async _loadAllOrders() {
    try {
      this.toastId = Toast.info("Loading..", null, false);
      const response = await getAllOrders();
      Toast.dismiss(this.toastId);
      const orders = response.data.orders;
      if (orders instanceof Array) {
        this.setState({ loading: false, orders });
      } else {
        this.setState({ loading: false });
      }
    } catch (e) {
      Toast.error("Unable to load the page. Please reload the page and try.", this.toastId);
      console.log('Error in _loadMyOrders', e);
    }
  }

  async _loadOrder(orderId) {
    try {
      Toast.dismiss(this.toastId);
      this.toastId = Toast.info("Loading..", null, false);
      const response = await getOrder(orderId);
      const order = response.data.order;
      if (order instanceof Object) {
        Toast.dismiss(this.toastId);
        this.setState({ showOrder: { orderId: order.id, order } });
      } else {
        Toast.error("Unable to load the order. Please reload the page and try again.", this.toastId, false);
        this.setState({ showOrder: null });
      }
    } catch (e) {
      console.log('Error in _loadOrder', e, orderId);
      Toast.error("Unable to load the order. Please reload the page and try again.", this.toastId, false);
      this.setState({ showOrder: null });
    }
  }

  async _changeStatus(order, status) {
    try {
      this.toastId = Toast.info(`Changing order status to '${status}'. Please wait..`, null, false);
      const response = await changeOrderStatus(order.id, status);
      order = response.data.order;
      Toast.success(`Order status changed to '${order.status}'.`, this.toastId);

      this.setState(prevState => {
        const orders = upsertArray(prevState.orders, order);
        return { orders };
      });
    } catch (e) {
      Toast.error("Something went wrong. Please try again.", this.toastId);
      console.log('Error in _changeStatus', e, order, status);
    }
  }

  handleDetailsClicked(order) {
    this.setState({ showOrder: { orderId: order.id, order: null } });
    this._loadOrder(order.id);
  }

  handleChangeStatus(order, status) {
    this._changeStatus(order, status);
  }

  renderOrderDetails(order) {
    return (
      <div>
        <div className="heading">
          <h4>Order ID: {order.id}</h4>
        </div>
        <p>Order placed on <span style={{ whiteSpace: 'nowrap' }}>{getReadableDateTimeString(order.createdAt)}</span></p>

        <div className="row">
          <div className="col-lg-12">
            <div className="heading">
              <h4>Order Status</h4>
            </div>
            <OrderStatusForm status={order.status} statusTypes={Object.keys(this.state.statusTypes)} onSubmit={(status) => this.handleChangeStatus(order, status)} />
          </div>
        </div>
        <br />
        <div className="box">
          <div className="heading">
            <h4>Order Details</h4>
          </div>
          <OrderTable rows={order.products} />
          <div className="box-footer">
            {
              order.creator &&
              <div>
                <div><b>Name:</b> {order.creator.name || '-'}</div>
                <div><b>Phone:</b> {order.creator.phone || '-'}</div>
                <div><b>Email:</b> {order.creator.email || '-'}</div>
              </div>
            }
            <b>Shipping Address:</b>
            <div>
              {
                order.shippingAddress ? <NewlineToBr text={order.shippingAddress} /> : <span style={{ color: "#666" }}>Not available</span>
              }
            </div>
          </div>
        </div>
        <PaymentDetails payments={order.payments} />
      </div>
    )
  }

  render() {

    const { loading, showOrder, statusTypes, statusFilter } = this.state;

    let orders = this.state.orders;
    if (statusFilter !== 'Show All') {
      orders = orders.filter(order =>
        statusTypes[order.status] === statusFilter || order.status === statusFilter
      );
    }

    return (
      <div className="container animated fadeIn">
        <SimpleDialog
          show={showOrder != null}
          onClose={() => this.setState({ showOrder: null })}
          title="Order details"
          body={showOrder ? showOrder.order ? this.renderOrderDetails(showOrder.order) : <h4>Loading..</h4> : <span />
          }
        />
        <div className="col-md-9">
          <div className="heading">
            <h2>Orders</h2>
          </div>

          <select style={{ padding: '8px 16px' }} value={statusFilter || 'Show All'} onChange={(e) => this.setState({ statusFilter: e.target.value })}>
            <option key='Show All' value='Show All'>Show all</option>
            <option key='Open' value='Open'>Open</option>
            <option key='Closed' value='Closed'>Closed</option>
            {
              Object.keys(statusTypes).map(statusType => <option key={statusType} value={statusType}>{statusType}</option>)
            }
          </select>
          <br />
          <br />
          {
            loading
              ? <h4 key={"alertinfo-loading"}>Loading..</h4>
              : orders.length === 0
                ? <div key={"alertinfo-no-orders"} className="animated fadeIn alert alert-info">No order is present</div>
                :
                <div className="row">
                  <div className="col-md-12">

                    <table key={statusFilter} className="table animated fadeIn">
                      <thead>
                        <tr>
                          <th>Order ID</th>
                          <th>Order placed on</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          orders.map((order, index) =>
                            <OrderRow key={order.id} order={order} statusTypes={statusTypes} rowNum={index + 1} handleDetailsClicked={(order) => this.handleDetailsClicked(order)} />
                          )
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
          }
        </div>
      </div>
    );
  }
}

function PaymentDetails({ payments }) {
  return (
    <div className="row">
      <div className="col-md-12 clearfix">
        <div className="heading">
          <h4>Payment Details</h4>
        </div>
        {
          (payments instanceof Array && payments.length > 0) ?
            <div className="box">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Amount</th>
                      <th>Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      payments.map(payment =>
                        <tr key={`payment-details-${payment.createdAt}`}>
                          <th><span style={{ color: "#888", whiteSpace: "nowrap" }}>{getReadableDateTimeString(payment.createdAt)}</span></th>
                          <td className="col-sm-2">{payment.amount}</td>
                          <td className="col-sm-7"><NewlineToBr text={payment.details} /></td>
                        </tr>
                      )
                    }
                  </tbody>
                </table>
              </div>
            </div>
            : <div>No payment details provided.</div>
        }
      </div>
    </div>
  )
}

function OrderStatusForm({ onSubmit, status, statusTypes }) {
  return (
    <Formik
      initialValues={{
        status: status
      }}
      onSubmit={(values, { resetForm }) => onSubmit(values.status, resetForm)}
      validationSchema={yup.object().shape({
        status: yup.string()
      })}
      render={({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
        return (
          <form className="form-inline" onSubmit={handleSubmit}>
            <select id="status" name="status" className="form-control" value={values.status || 'Pending'} onChange={handleChange}>
              {
                statusTypes.map(statusType => <option key={statusType} value={statusType}>{statusType}</option>)
              }
            </select>
            {' '}
            <button type="submit" className="btn btn-template-primary">Change</button>
            {errors.status && touched.status &&
              <p>{errors.status}</p>
            }
          </form>
        );
      }} />
  )
}

function OrderRow(props) {
  const { order, statusTypes, rowNum, handleDetailsClicked } = props;
  const statusCellStyle = statusTypes[order.status] === 'Open' ? { color: '#ff7621', fontWeight: 'bold' } : { color: '#666' };
  const statusRowStyle = statusTypes[order.status] === 'Open' ? { backgroundColor: '#fff', fontWeight: 'bold' } : { backgroundColor: '#f6f6f6' };
  return (
    <tr style={statusRowStyle}>
      <td>{order.id}</td>
      <td><span style={{ whiteSpace: 'nowrap' }}>{getReadableDateTimeString(order.createdAt)}</span></td>
      <td style={statusCellStyle}>{order.status || "-"}</td>
      <td>
        <button className="btn btn-default" onClick={(e) => handleDetailsClicked(order)}>
          View/Edit <i className="fa fa-chevron-right"></i>
        </button>
      </td>
    </tr>
  )
}
