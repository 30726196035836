import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from "yup";

import { validateResetPasswordToken, resetPassword } from '../services/api';

class ResetPassword extends Component {

  constructor(props) {
    super(props);

    this.state = {
      displayMessage: "Loading..",
      displayMessageType: "info",
      status: "LOADING"
    };
  }

  componentDidMount() {
    const { match: { params } } = this.props;
    const token = params.token;

    if (!token) {
      this.setState({ status: "VALIDATION_ERROR" });
      this.showMessage("This link is invalid", "danger");
      return;
    }

    this._validateResetPasswordToken(token);
  }

  async _validateResetPasswordToken(token) {
    try {
      this.setState({ status: "LOADING" });
      this.showMessage("Loading.. Please wait..", "info");
      await validateResetPasswordToken(token);
      this.setState({ status: "VALIDATION_SUCCESS" });
    } catch (e) {
      console.error('Error in _validateResetPasswordToken', e);
      if (e.response && e.response.status === 401) {
        this.setState({ status: "VALIDATION_ERROR" });
        this.showMessage("This link is invalid", "danger");
      } else {
        this.setState({ status: "VALIDATION_ERROR" });
        this.showMessage("Something went wrong. Please reload the page.", "danger");
      }
    }
  }

  async _resetPassword(email, password, token) {
    try {
      this.setState({ status: "LOADING" });
      this.showMessage("Submitting.. Please wait..", "info");
      await resetPassword(email, password, token);
      this.setState({ status: "SUBMIT_SUCCESS" });
      this.showMessage("Your password is changed successfully. You can now login.", "success");
    } catch (e) {
      console.error('Error in _validateResetPasswordToken', e);
      if (e.response && e.response.status === 401) {
        this.setState({ status: "VALIDATION_ERROR" });
        this.showMessage("This link is invalid.", "danger");
      } else {
        this.setState({ status: "SUBMIT_ERROR" });
        this.showMessage('Something went wrong. Please try again.', 'danger');
      }
    }
  }

  handleSubmit(email, password) {
    const { match: { params } } = this.props;
    const token = params.token;

    this._resetPassword(email, password, token);
  }

  showMessage(message, type) {
    this.setState({ displayMessage: message, displayMessageType: type });
  }

  render() {
    return (
      <div style={{ padding: "32px" }} className="animated fadeIn">
        <div className='row'>
          <div className='container'>
            <div className='col-md-7'>
              <div className="heading">
                <h2>Reset Password</h2>
              </div>
              {
                this.renderBody()
              }
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderBody() {
    switch (this.state.status) {
      case 'LOADING': return <div><div>{this.renderMessageBox()}</div></div>
      case 'VALIDATION_ERROR': return <div><div>{this.renderMessageBox()}</div></div>
      case 'VALIDATION_SUCCESS': return this.renderForm()
      case 'SUBMIT_ERROR': return <div><div>{this.renderForm(true)}</div></div>
      case 'SUBMIT_SUCCESS': return (
        <div><div>
          {this.renderMessageBox()}
          <Link to={"/login-signup"}><button className="btn btn-template-primary">Go to Login Page</button></Link>
        </div></div>
      )
    }
  }

  renderMessageBox() {
    return (
      <Alert key={this.state.displayMessage} className="animated fadeIn" bsStyle={this.state.displayMessageType}>
        {this.state.displayMessage}
      </Alert>
    )
  }

  renderForm(renderMessageBox = false) {
    return (
      <div>
        <div>
          {renderMessageBox && this.renderMessageBox()}
          <Formik
            initialValues={{
              email: '',
              password: '',
              confirmPassword: '',
            }}
            onSubmit={(values) => this.handleSubmit(values.email, values.password)}
            validationSchema={yup.object().shape({
              email: yup.string().email().required(),
              password: yup.string().required('Password is required').min(6),
              confirmPassword: yup.string()
                .oneOf([yup.ref('password'), null], "Passwords don't match")
                .required('Re-enter password here')
            })}
            render={({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
              return (
                <form onSubmit={handleSubmit}>

                  <div className="form-group">
                    <label>Email</label>
                    <input type="text" className="form-control" id="email"
                      value={values.email} onChange={handleChange} />
                    {errors.email && touched.email &&
                      <p>{errors.email}</p>
                    }
                  </div>

                  <div className="form-group">
                    <label htmlFor="password">New Password</label>
                    <input type="password" className="form-control" id="password"
                      value={values.password} onChange={handleChange} />
                    {errors.password && touched.password &&
                      <p>{errors.password}</p>
                    }
                  </div>

                  <div className="form-group">
                    <label htmlFor="confirmPassword">Re-enter Password</label>
                    <input type="password" className="form-control" id="confirmPassword"
                      value={values.confirmPassword} onChange={handleChange} />
                    {errors.confirmPassword && touched.confirmPassword &&
                      <p>{errors.confirmPassword}</p>
                    }
                  </div>

                  <div>
                    <button type="submit" className="btn btn-template-primary">Save</button>
                  </div>

                </form>
              );
            }} />
        </div>
      </div>
    )
  }
}


export default ResetPassword;
