import React, { Component } from 'react';
import { Switch, Route, Redirect, NavLink, Link } from 'react-router-dom';

import Toast from '../../components/Toast';

import { isEmptyString } from '../../services/util';
import { getProductCategoriesWithSubcategories } from '../../services/api';
import * as util from '../../services/util';
import { upsertCategory, upsertSubcategory, deleteCategory, deleteSubcategory } from '../../services/adminApi';

export default class ProductCategories extends Component {

  state = {
    categorySubcategoryList: [],
    loading: false,
    newCategory: {},
    newSubcategory: {},
    newSubcategoryCategory: {},
    editCategory: {},
    editSubcategory: {},
    editSubcategoryCategory: {},
  }

  categoryNodes = [];
  newlyAddedCategoryId = null;

  componentDidMount() {
    this._loadCategories();
  }

  async _loadCategories() {
    try {
      this.toastId = Toast.info("Loading categories..", null, false);
      const response = await getProductCategoriesWithSubcategories();
      Toast.dismiss(this.toastId);
      const categorySubcategoryList = response.data.cats;
      this.setState({ categorySubcategoryList });
    } catch (e) {
      Toast.error("Error loading categories.", this.toastId);
      console.log('Error in _loadCategories', e);
    }
  }

  componentDidUpdate() {
    console.log("componentDidUpdate", new Date().toISOString(), this.newlyAddedCategoryId);
    if (this.newlyAddedCategoryId != null) {
      let node = this.categoryNodes[this.newlyAddedCategoryId];
      this.scrollNodeToViewAnimated(node);
      this.newlyAddedCategoryId = null;
    }
  }

  scrollNodeToViewAnimated(node) {
    node.scrollIntoView();
    const originalBackgroundColor = node.style.backgroundColor;
    node.style.backgroundColor = 'hsl(50,100%,80%)';
    var d = 1000;
    for (var i = 80; i <= 100; i = i + 0.5) { //i represents the lightness
      d += 10;
      (function (ii, dd) {
        setTimeout(function () {
          console.log('node ', ii);
          if(ii < 99) {
            node.style.backgroundColor = 'hsl(50,100%,' + ii + '%)';
          } else {
            node.style.backgroundColor = originalBackgroundColor;
          }
        }, dd);
      })(i, d);
    }
  }

  onAddNewCategorySubmitted(e) {
    const { newCategory } = this.state;

    if (isEmptyString(newCategory.name)) {
      alert("Category name cannot be empty");
      return;
    }

    this._upsertCategory(newCategory);
  }

  async _upsertCategory(category) {
    try {
      const isUpdate = category.id != null;
      this.toastId = Toast.info(`Saving Category '${category.name}'. Please wait..`, null, false);
      const response = await upsertCategory(category);
      Toast.success(`Category '${category.name}' saved.`, this.toastId);
      let subcategories = category.subcategories instanceof Array ? [ ...category.subcategories ] : null;
      category = response.data.cat;
      if(category.subcategories == null && subcategories != null) {
        category.subcategories = subcategories;
      }

      this.setState(prevState => {
        const categorySubcategoryList = util.upsertArray(prevState.categorySubcategoryList, category);
        if(isUpdate) {
          return { categorySubcategoryList, editCategory: {} };
        } else {
          this.newlyAddedCategoryId = category.id;
          return { categorySubcategoryList, newCategory: {} };
        }
      });
    } catch (e) {
      Toast.error("Something went wrong. Please try again.", this.toastId);
      console.log('Error in _upsertCategory', e, category);
    }
  }

  onAddNewSubcategorySubmitted(e) {
    const { newSubcategory, newSubcategoryCategory } = this.state;

    if (isEmptyString(newSubcategory.name)) {
      alert("Sub-category name cannot be empty");
      return;
    }

    this._upsertSubcategory(newSubcategory, newSubcategoryCategory);
  }

  async _upsertSubcategory(subcategory, category) {
    try {
      const isUpdate = subcategory.id != null;
      this.toastId = Toast.info(`Saving Sub-category '${subcategory.name}'. Please wait..`, null, false);
      const response = await upsertSubcategory(subcategory, category);
      Toast.success(`Sub-category '${subcategory.name}' saved.`, this.toastId);
      subcategory = response.data.subCat;
      this.setState(prevState => {
        let newCategory = { ...prevState.categorySubcategoryList.find(a => a.id == category.id) };
        newCategory.subcategories = util.upsertArray(newCategory.subcategories, subcategory);
        let categorySubcategoryList = util.upsertArray(prevState.categorySubcategoryList, newCategory);
        return { categorySubcategoryList, newSubcategory: {}, newSubcategoryCategory: {} };
      });
    } catch (e) {
      Toast.error("Something went wrong. Please try again.", this.toastId);
      console.log('Error in _upsertSubcategory', e, subcategory, category);
    }
  }

  handleDeleteCategory(category) {
    if (window.confirm("Delete category '" + category.name + "' ?")) {
      this._deleteCategory(category);
    }
  }

  async _deleteCategory(category) {
    try {
      this.toastId = Toast.info(`Deleting category '${category.name}'..`, null, false);
      const response = await deleteCategory(category.id);
      Toast.success(`Category '${category.name}' deleted.`, this.toastId);
      this.setState(prevState => ({
        categorySubcategoryList: [...prevState.categorySubcategoryList.filter(a => a.id != category.id)]
      }));
    } catch (e) {
      Toast.error("Something went wrong. Please try again.", this.toastId);
      console.log('Error in _deleteCategory', e, category);
    }
  }

  handleDeleteSubcategory(subcategory, category) {
    if(window.confirm("Delete sub-category '" + subcategory.name + "' ?")) {
      this._deleteSubcategory(subcategory, category);
    }
  }

  async _deleteSubcategory(subcategory, category) {
    try {
      this.toastId = Toast.info(`Deleting sub-category '${subcategory.name}'..`, null, false);
      const response = await deleteSubcategory(subcategory.id, category.id);
      Toast.success(`Sub-category '${subcategory.name}' deleted.`, this.toastId);
      this.setState(prevState => {
        let newCategory = { ...prevState.categorySubcategoryList.find(a => a.id == category.id) };
        newCategory.subcategories = newCategory.subcategories.filter((a) => a.id != subcategory.id);
        let categorySubcategoryList = util.upsertArray(prevState.categorySubcategoryList, newCategory);
        return { categorySubcategoryList };
      });
    } catch (e) {
      Toast.error("Something went wrong. Please try again.", this.toastId);
      console.log('Error in _deleteSubcategory', e, subcategory, category);
    }
  }

  handleEditCategoryNameChange(e) {
    const newName = e.target.value;
    this.setState(prevState => ({ editCategory: { ...prevState.editCategory, name: newName } }));
  }

  handleNewCategoryNameChange(e) {
    const newName = e.target.value;
    this.setState(prevState => ({ newCategory: { ...prevState.newCategory, name: newName } }));
  }

  handleEditSubcategoryNameChange(e) {
    const newName = e.target.value;
    this.setState(prevState => ({ editSubcategory: { ...prevState.editSubcategory, name: newName } }));
  }

  handleNewSubcategoryNameChange(e) {
    const newName = e.target.value;
    this.setState(prevState => ({ newSubcategory: { ...prevState.newSubcategory, name: newName } }));
  }

  onEditCategorySave(e) {
    const { editCategory } = this.state;

    if (isEmptyString(editCategory.name)) {
      alert("Category name cannot be empty");
      return;
    }

    this._upsertCategory(editCategory);
  }

  onEditSubcategorySave(e) {
    const { editSubcategory, editSubcategoryCategory } = this.state;

    if (isEmptyString(editSubcategory.name)) {
      alert("Sub-category name cannot be empty");
      return;
    }

    upsertSubcategory(editSubcategory, editSubcategoryCategory);

    this.setState(prevState => {
      let categorySubcategoryList = [...prevState.categorySubcategoryList];
      let categoryWithSubcategoryEdited = categorySubcategoryList.find((a) => a.id == editSubcategoryCategory.id);
      const subcategoryIndex = categoryWithSubcategoryEdited.subcategories.findIndex((a) => a.id == editSubcategory.id);
      categoryWithSubcategoryEdited.subcategories[subcategoryIndex] = editSubcategory;

      const categoryIndex = categorySubcategoryList.findIndex((a) => a.id == editSubcategoryCategory.id);
      categorySubcategoryList[categoryIndex] = categoryWithSubcategoryEdited;
      return { categorySubcategoryList, editSubcategory: {}, editSubcategoryCategory: {} };
    });
  }

  render() {

    const { categorySubcategoryList, loading, newCategory, newSubcategory, newSubcategoryCategory, editCategory, editSubcategory, editSubcategoryCategory, addNewSubcategory } = this.state;

    return (
      <div className="container animated fadeIn">
        <div className="col-md-9">
          <div className="heading">
            <h2>Product Categories</h2>
          </div>
          {
            loading
              ? <h4>Loading..</h4>
              : <ul className="list-group admin-product-categories">
                <li className="list-group-item" key="add-new-product-category">
                  {
                    this.renderAddNewCategory(newCategory)
                  }
                </li>
                {
                  categorySubcategoryList && categorySubcategoryList.length === 0 ? this.renderNoCategoryWarning()
                    : categorySubcategoryList.map((category, index) =>
                      <li className="list-group-item animated fadeIn" style={index%2===0 ? {backgroundColor: "#eee"} : {backgroundColor: "#fff"}} key={category.id} ref={node => this.categoryNodes[category.id] = node}>
                        <table className="table">
                          <thead>
                            {
                              this.renderCategory(editCategory, category)
                            }
                          </thead>
                          <tbody>
                            {
                              this.renderSubcategoryRows(category, editSubcategory, editSubcategoryCategory)
                            }
                            {
                              this.renderAddNewSubcategory(newSubcategoryCategory, newSubcategory, category)
                            }
                          </tbody>
                        </table>
                      </li>
                    )
                }
              </ul>
          }
        </div>
      </div>
    );
  }

  renderAddNewCategory(newCategory) {
    return (
      <React.Fragment>
        <h4>Add new category</h4>
        <table>
          <tbody>
            <tr>
              <td className="col-xs-8 col-sm-4 col-md-4">
                <input type="text" className="form-control" placeholder="Enter new category name" value={newCategory.name || ""} onChange={(e) => this.handleNewCategoryNameChange(e)} />
              </td>
              <td className="col-xs-4 col-sm-4 col-md-4">
                <button className="btn btn-default" onClick={(e) => this.onAddNewCategorySubmitted(e)}>
                  <i className="fa fa-plus"></i> Add
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </React.Fragment>
    );
  }

  renderNoCategoryWarning() {
    return (
      <div style={{ marginTop: 30 }} className="animated fadeIn alert alert-warning">No category is present</div>
    );
  }

  renderCategory(editCategory, category) {
    return (
      editCategory.id == category.id ?
        <tr key={"edit-category-" + category.id}>
          <td className="col-xs-8 col-sm-4 col-md-4">
            <input type="text" className="form-control"
              placeholder="Enter new category name"
              value={editCategory.name}
              onChange={(e) => this.handleEditCategoryNameChange(e)} />
          </td>
          <td className="col-xs-4 col-sm-4 col-md-4">
            <button className="btn btn-default" onClick={(e) => this.onEditCategorySave(e)}>
              <i className="fa fa-plus"></i> Save
              </button>&nbsp;&nbsp;
              <button className="btn btn-default" onClick={(e) => this.setState({ editCategory: {} })}>
              Cancel
              </button>
          </td>
        </tr>
        :
        <tr key={"row-category-" + category.id}>
          <td>
            <h4>{category.name}</h4>
          </td>
          <td>
            <button onClick={() => this.setState({ editCategory: category })} className="btn btn-default">
              <i className="fa fa-pencil"></i> EDIT
              </button>&nbsp;&nbsp;
              <button onClick={() => this.handleDeleteCategory(category)} className="btn btn-default">
              <i className="fa fa-close"></i> REMOVE
              </button>
          </td>
        </tr>
    );
  }

  renderSubcategoryRows(category, editSubcategory, editSubcategoryCategory) {
    console.log("renderSubcategoryRows", category, editSubcategory.id);
    if(category.subcategories == null) {
      return null;
    }
    return (
      category.subcategories.map(subcategory =>
        editSubcategory.id && editSubcategory.id == subcategory.id ?
          <tr key={"edit-subcategory-" + subcategory.id}>
            <td className="col-xs-8 col-sm-4 col-md-4">
              <input type="text" className="form-control"
                placeholder="Enter new sub-category name"
                value={editSubcategory.name}
                onChange={(e) => this.handleEditSubcategoryNameChange(e)} />
            </td>
            <td className="col-xs-4 col-sm-4 col-md-4">
              <button className="btn btn-default" onClick={(e) => this.onEditSubcategorySave(e)}>
                <i className="fa fa-plus"></i> Save
              </button>&nbsp;&nbsp;
              <button className="btn btn-default" onClick={(e) => this.setState({ editSubcategoryCategory: {}, editSubcategory: {} })}>
                Cancel
              </button>
            </td>
          </tr>
          : <tr key={"row-subcategory-" + subcategory.id}>
            <td style={{ verticalAlign: "middle" }}>
              {subcategory.name}
            </td>
            <td>
              <button onClick={() => this.setState({ editSubcategory: subcategory, editSubcategoryCategory: category })} className="btn btn-default">
                <i className="fa fa-pencil"></i> EDIT
              </button>&nbsp;&nbsp;
              <button onClick={() => this.handleDeleteSubcategory(subcategory, category)} className="btn btn-default">
                <i className="fa fa-close"></i> REMOVE
              </button>&nbsp;&nbsp;
              <Link to={'/products/' + subcategory.id} className="btn btn-default" target="_blank">
                <i className="fa fa-sign-out"></i> Open in main site
              </Link>
            </td>
          </tr>
      )
    );
  }

  renderAddNewSubcategory(newSubcategoryCategory, newSubcategory, category) {
    return (
      newSubcategoryCategory.id && newSubcategoryCategory.id == category.id ?
        <tr key={"add-new-product-subcategory" + category.id}>
          <td>
            <input type="text" className="form-control"
              placeholder="Enter new sub-category name"
              value={newSubcategory.name || ''}
              onChange={(e) => this.handleNewSubcategoryNameChange(e)} />
          </td>
          <td>
            <button className="btn btn-default" onClick={(e) => this.onAddNewSubcategorySubmitted(e)}>
              <i className="fa fa-plus"></i> Add
            </button>&nbsp;&nbsp;
            <button className="btn btn-default" onClick={(e) => this.setState({ newSubcategoryCategory: {}, newSubcategory: {} })}>
              Cancel
            </button>
          </td>
        </tr>
        :
        <tr key={"add-new-product-subcategory-button-" + category.id}>
          <td colSpan="2">
            <button className="btn btn-default" onClick={(e) => this.setState({ newSubcategoryCategory: category, newSubcategory: {} })}>
              <i className="fa fa-plus"></i> Add new subcategory
            </button>
          </td>
        </tr>
    );
  }
}

