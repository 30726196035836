import React, { Component } from 'react';
import { Route, NavLink } from 'react-router-dom';
import { Label } from 'react-bootstrap';

import Toast from '../components/Toast';
import Section from '../components/Section';
import QuantityInputBox from '../components/QuantityInputBox';

import { getProductDetails, getProductQuantityInCart, setProductQuantityInCart } from '../services/api';

export default class ProductDetails extends Component {

  state = {
    product: null,
    quantity: 0,
    is404: false
  };

  componentDidMount() {
    console.log("ProductDetails: componentDidMount", new Date().toISOString(), this.props);
    this.loadPage(this.props);
  }

  componentWillReceiveProps(nextProps) {
    console.log("ProductDetails: componentWillReceiveProps", new Date().toISOString(), nextProps);
    this.loadPage(nextProps);
  }

  loadPage(props) {
    const { match: { params: { productId } } } = props;

    if (!productId) {
      this.props.history.push(`/products`);
      return;
    }

    this._loadProductDetails(productId);
  }

  async _loadProductDetails(productId) {
    if (productId == this.state.productId) {
      return;
    }
    console.log("productId: ", productId);

    try {
      this.toastId = Toast.info("Loading..", null, false);
      const response = await getProductDetails(productId);
      Toast.dismiss(this.toastId);
      const product = response.data.prod;
      const quantity = getProductQuantityInCart(productId);
      this.setState({ product, quantity });
    } catch (e) {
      console.log('Error in _loadProductDetails', e);
      if(e.response && e.response.status == 404) {
        Toast.dismiss(this.toastId);
        this.setState({is404: true});
        //Toast.error("Sorry. This page does not exist", this.toastId, false);
      } else {
        Toast.error("Unable to load the page. Please reload the page and try.", this.toastId);
      }
    }
  }

  onQuantityChange(newQuantity) {
    console.log("onQuantityChange", newQuantity);
    const quantity = parseInt(newQuantity);
    this.setState({ quantity });
    setProductQuantityInCart(this.state.product, quantity);
  }

  render() {
    const { product, quantity, is404 } = this.state;
    return (
      <div className="animated fadeIn">
        <Section>
          {
            is404 ? [<h4>Sorry! This page does not exist.</h4>, <BackToProductsButton />]
            : product == null ? <h4>Loading..</h4>
              : <ProductDetailsComponent quantity={quantity} onQuantityChange={(q) => this.onQuantityChange(q)} product={product} />
          }
        </Section>
      </div>
    );
  }
}

function ProductDetailsComponent(props) {
  const { product, quantity, onQuantityChange } = props;

  return (
    <div>
      <div className="row">
        <div className="col-sm-4">
          <div id="mainImage">
            <img src={product.imageSrc} alt="" className="img-responsive" />
          </div>
        </div>
        <div className="col-sm-8">
          <h2>{product.name}</h2>
          { product.tag && [<Label>{product.tag}</Label>,<br/>,<br/>] }
          <p>{product.description}</p>
          <div>
            {
              quantity
                ? <div>
                  <label>Quantity: </label>
                  <span style={{ marginLeft: 10 }}>
                    <QuantityInputBox quantity={quantity} onQuantityChange={onQuantityChange} />
                    <button
                      onClick={(e) => { e.preventDefault(); onQuantityChange(0) }}
                      style={{marginLeft: 20}}
                      className="btn btn-default">
                      <i className="fa fa-trash-o"></i> Remove
                    </button>
                  </span>
                </div>
                : <button
                  onClick={(e) => { e.preventDefault(); onQuantityChange(1) }}
                  className="btn btn-template-main">
                  <i className="fa fa-shopping-cart"></i> Add to cart
                </button>
            }

            <br />
            <br />
            <div>
              <div>
                <BackToProductsButton />
                <NavLink style={{ marginLeft: 10 }} className="btn btn-template-primary" to="/cart"><i className="fa fa-shopping-cart"></i> View Cart</NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function BackToProductsButton() {
  return (
    <NavLink to="/products" className="btn btn-default"><i className="fa fa-chevron-left"></i> Back to Products</NavLink>
  )
}
